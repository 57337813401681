import { Service } from 'typedi';
import { isJSONString } from 'utils/object-handler';

@Service()
export class SessionStorageService {

    private toType(obj: any): string {
        const object = ({}).toString.call(obj).match(/\s([a-z|A-Z]+)/);
        if (object) {
            return object[1].toLowerCase();
        }
        return 'string';
    }

    set(name: string, value: any): void {
        const type = this.toType(value);

        if (/object|array/.test(type)) {
            value = JSON.stringify(value);
        }

        sessionStorage.setItem(name, value);
    }

    get(name: string): any {
        let item = sessionStorage.getItem(name) || '';

        return isJSONString(item) ? JSON.parse(item) : item;
    }

    remove(name: string): void {
        sessionStorage.removeItem(name);
    }

    removeAll(): void {
        sessionStorage.clear();
    }

    removeAllAndKeep(keys: string[]): void {
        Object.keys(sessionStorage).forEach(k => keys.indexOf(k) === -1 && sessionStorage.removeItem(k));
    }
}
