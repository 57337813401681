import { TLabelColor, LABEL_COLOR } from 'ui/label/label-outline/label-outline';
import {
	TOrderItemDetails, TCustomerDetails,
	// TVoucherGuestView //TODO:
} from 'voucher/model/voucher-package.model';
import { TOrderStatus, TMarkedOrderStatus } from 'models/hotel-pkg.model';
import { TRecipient } from 'models/hotel-pkg/hotel-order.model';
import { Address } from 'models/common.model';
import Container from 'typedi';
import { TranslationService } from 'services/translation.service';

type TOrderActions =
	'cancel' | 'edit' | 'update_payment_email' | 'send_payment_request' | 'order_confirmation_email' |
	'send_e_vouchers' | 'mark_as_invoiced' | 'mark_as_deposit_received' | 'mark_as_paid' | 'send_voucher_code_list' |
	'mail_order_cancelation' | 'mail_voucher_cancelation' | 'add_tracking_number';

type TOrderStatusMap = {
	text: string;
	colorCode: TLabelColor;
	des: string;
};

export const OrderUtil: any = {

	get translator() {
		return Container.get(TranslationService).translator
	},

	get MARKED_ORDER_STATUS(): { [key in TMarkedOrderStatus]: string } {
		return ({
			confirmed: OrderUtil.translator('Order.Status.paid'),
			invoiced: OrderUtil.translator('Order.Status.Invoiced.text'),
			deposit_received: OrderUtil.translator('Order.Status.DepositReceived.text')
		})
	},

	get ORDER_STATUS_MAP(): { [key in TOrderStatus]: TOrderStatusMap } {
		return ({
			confirmed: {
				text: OrderUtil.translator('Order.Status.Confirmed.text'),
				colorCode: 'IDEM_GREEN',
				des: OrderUtil.translator('Order.Status.Confirmed.desc')
			},
			redeemed: {
				text: OrderUtil.translator('Order.Status.Redeemed.text'),
				colorCode: 'BLUE',
				des: OrderUtil.translator('Order.Status.Redeemed.desc')
			},
			pending: {
				text: OrderUtil.translator('Order.Status.Pending.text'),
				colorCode: 'MANGO',
				des: OrderUtil.translator('Order.Status.Pending.desc')
			},
			cancelled: {
				text: OrderUtil.translator('Order.Status.Cancelled.text'),
				colorCode: 'BROWN_GRAY',
				des: OrderUtil.translator('Order.Status.Cancelled.desc')
			},
			deposit_received: {
				text: OrderUtil.translator('Order.Status.DepositReceived.text'),
				colorCode: 'IDEM_DARK_BLUE',
				des: OrderUtil.translator('Order.Status.DepositReceived.desc')
			},
			invoiced: {
				text: OrderUtil.translator('Order.Status.Invoiced.text'),
				colorCode: 'IDEM_ORANGE',
				des: OrderUtil.translator('Order.Status.Invoiced.desc')
			},
			expired: {
				text: OrderUtil.translator('Order.Status.Expired.text'),
				colorCode: 'BRAND_RED_DISABLED',
				des: OrderUtil.translator('Order.Status.Expired.desc')
			}
		})
	},

	orderStatusToText: (status: TOrderStatus): string => {
		return OrderUtil.ORDER_STATUS_MAP[status].text;
	},

	orderStatusToColorCode: (status: TOrderStatus): TLabelColor => {
		return OrderUtil.ORDER_STATUS_MAP[status].colorCode;
	},

	orderStatusToColor: (status?: TOrderStatus): string => {
		return status ? LABEL_COLOR[OrderUtil.orderStatusToColorCode(status) as TLabelColor] : LABEL_COLOR['BLACK'];
	},

	checkAction: (actionType: TOrderActions, permission: string[]): boolean => {
		return permission.includes(actionType);
	},

	isVoucherEnabled: (): boolean => {
		return VOUCHER_SETTINGS ? VOUCHER_SETTINGS.enabled : false;
	},

// TODO:
// 	buildRecipients: (orderItems: TOrderItemDetails[], sameRecipient: boolean, markIndex?: boolean): TVoucherGuestView[] => {
// 	const recipients: TVoucherGuestView[] = [];
// 	const recipientIds: string[] = [];

// 	orderItems.forEach((oi, index) => {
// 		const {
// 			guest,
// 			message, description } = oi;
// 		const voucherDescription = markIndex
// 			? `Voucher #${index + 1} - ${description}`
// 			: description;

// 		const recipientIndex = sameRecipient ? 0 : recipientIds.indexOf(guest.id);

// 		if (recipientIndex === -1 || recipients.length === 0) {
// 			recipients.push({
// 				...guest,
// 				message,
// 				voucherInfo: [{ id: oi.id, name: voucherDescription }]
// 			});
// 			recipientIds.push(guest.id);
// 		} else {
// 			recipients[recipientIndex].voucherInfo.push({ id: oi.id, name: voucherDescription })
// 		}
// 	});

// 	return recipients;
// }

	getVoucherNames: (recipient: TRecipient, orderItems: TOrderItemDetails[]): string[] => {
		const filteredOrderItems = OrderUtil.getOrderItemsOfRecipient(recipient, orderItems);

		return filteredOrderItems.map((item: TOrderItemDetails) => item.description);
	},

	getOrderItemsOfRecipient: (recipient: TRecipient, orderItems: TOrderItemDetails[]): TOrderItemDetails[] => {
		return orderItems.filter(orderItem => recipient.items.some(re => re.order_item_id === orderItem.id))
	},

	getGuestName: (guest: TCustomerDetails): string => {
		return `${guest.salutation} ${guest.first_name} ${guest.last_name}`.trim();
	},

	getPhysicalDeliveryProductName: (orderItems: TOrderItemDetails[]): string => {
		return orderItems.reduce((all, oi) =>
			oi.physical_delivery
				? (all ? `${all}, ${oi.description}` : oi.description)
				: all
			, '');
	},

	getShippingAddress: (address: Address): string => {
		return Object.keys(address).reduce((all, key) =>
			key === 'address_2'
				? all
				: all ? all + (address[key] ? ', ' + address[key] : '') : address[key]
			, '');
	}
}
