import React from 'react';
import cls from 'classnames';
import styles from './button-new.scss';
import { Link } from 'react-router-dom';
import { st } from 'constants/style-names-global';

export enum BUTTON_COLOR {
	RED = 'RED',
	IDEM_RED = 'IDEM_RED',
	GRAY = 'GRAY',
	TOPAZ = 'TOPAZ',
	GREEN = 'GREEN',
	ORANGE = 'ORANGE',
	WHITE = 'WHITE',
	BLACK = 'BLACK',
	GOLD = 'GOLD',
	VOUCHER_GREY = 'VOUCHER_GREY',
	CLASSIC = 'CLASSIC',
	G360_PRIMARY = 'G360_PRIMARY',
}

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface IButtonNewProps extends ButtonProps {
	className?: string;
	color?: BUTTON_COLOR;
	noBackground?: boolean;
	noPadding?: boolean;
	to?: string;
	href?: string;
	target?: string;
	fullWidth?: boolean;
	customHeight?: 30 | 37 | 50 | 80;
}

export class ButtonNew extends React.Component<IButtonNewProps> {
	render() {
		const { className, color, noBackground, noPadding, to, href, disabled, target, fullWidth, customHeight, style, ...rest } = this.props;

		const classCombined = cls({
			[styles.button]: true,
			[st.w100]: fullWidth,
			[styles.noBackground]: noBackground,
			[styles.noPadding]: noPadding,
			[styles.buttonRed]: color === BUTTON_COLOR.RED,
			[styles.buttonIdemRed]: color === BUTTON_COLOR.IDEM_RED,
			[styles.buttonGray]: color === BUTTON_COLOR.GRAY,
			[styles.buttonTopaz]: color === BUTTON_COLOR.TOPAZ,
			[styles.buttonGreen]: color === BUTTON_COLOR.GREEN,
			[styles.buttonOrange]: color === BUTTON_COLOR.ORANGE,
			[styles.buttonWhite]: color === BUTTON_COLOR.WHITE,
			[styles.buttonBlack]: color === BUTTON_COLOR.BLACK,
			[styles.buttonGold]: color === BUTTON_COLOR.GOLD,
			[styles.buttonVoucherGrey]: color === BUTTON_COLOR.VOUCHER_GREY,
			[styles.buttonClassic]: color === BUTTON_COLOR.CLASSIC,
			[styles.buttonG360Black]: color === BUTTON_COLOR.G360_PRIMARY,
		}, className);

		const styleObject = {
			...style,
			...customHeight ? { height: `${customHeight}px` } : {},
		};

		if (disabled) {
			return (
				<span style={{ cursor: 'not-allowed' }}>
					<button className={classCombined} disabled={true} {...rest as ButtonProps} style={{ ...styleObject, pointerEvents: 'none' }}>
						{this.props.children}
					</button>
				</span>
			)
		}

		if (to) {
			return (
				<Link to={to} style={{ textDecoration: 'none' }}>
					<button {...rest as ButtonProps} className={classCombined} style={styleObject}>
						{this.props.children}
					</button>
				</Link>
			);
		}

		if (href) {
			return (
				<a className={classCombined} href={href} target={target} style={{ ...styleObject, textDecoration: 'none' }}>{this.props.children}</a>
			);
		}

		return <button {...rest as ButtonProps} className={classCombined} style={styleObject}>{this.props.children}</button>;
	}
}
