import Container, { Service } from 'typedi';
import { DocumentService } from './document.service';

const documentService = Container.get(DocumentService)

@Service()
export class AwsConnectService {

    loadChatWidgetScript() {
        const scriptTag = documentService.createScriptTag({
            innerHTML: `(function (w, d, x, id) {
                            s = d.createElement('script');
                            s.src = 'https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js';
                            s.async = 1;
                            s.id = id;
                            d.getElementsByTagName('head')[0].appendChild(s);
                            w[x] = w[x] || function () { (w[x].ac = w[x].ac || []).push(arguments) };
                        })(window, document, 'amazon_connect', '1d132c1b-158a-4a13-b2b1-389a167615c3');
                        amazon_connect('styles', { openChat: { color: 'white', backgroundColor: '#123456' }, closeChat: { color: 'white', backgroundColor: '#123456' } });
                        amazon_connect('snippetId','QVFJREFIaGdrRkFyOStHNjU4cDN3RWRrZXp6QkY2NWpiaU1JWHIybjgvQmZ6VTkyNWdFSGxkdzFhcDlTWFpNVTVBcUxlejhVQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNV0lzdk9DWmhUTFhOSExtdkFnRVFnQ3U4TndVOWoraUxqaEtjL25mK0t2by84R1N4dWExVkdaMUFKMlNYMnF2ZWoyVnpPMWVXaDNOcW15N006OnFNTHFyWVAyRzNNMHhyUFRhR1dhKzlyRm4wMG5zZS9QNmFxUnl2ekF2cThseXR2aXJEQ3ZxdFdXUXgwUmFBZWY4ekt5eWVhMHhsdnNpd0xxdG93VFdNbEJFeTJYTy9ka0VnaXVncXBRMTRoUjdreUtDdGpuOVlNRHlCQ2hlcE5kcDlocWJNSnc3a3JxYk1aYXVnOE11a1VIVTh4Ly9Mdz0=');`
        })

        document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
}
