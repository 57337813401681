import * as Loadable from 'react-loadable';
import { RouteProps, RouteComponentProps, StaticContext } from 'react-router';

import { OrderUtil } from 'pages/hotel-pkgs/voucher-orders/orders-util';
import { ArrayUtil } from 'utils/array-uitl';

const NullLoading = (e: any): any => {
    if (e.error) {
        throw e.error;
    }
    return null;
}

const LoadableSafe = (loader: any) => Loadable({
    loader,
    loading: NullLoading,
});

export interface INewRouteProps extends RouteProps {
    path: string | string[];
}

export interface ICustomRouteProps extends INewRouteProps {
    routes?: INewRouteProps[];
    redirectFunc?: (props: RouteComponentProps<any, StaticContext, any>) => string;
}

const buildBlockDetailRoutes = (isHotel: boolean): ICustomRouteProps[] => [
    {
        path: '/blocks/:id/view',
        component: LoadableSafe(() => import('pages/block-detail/block-view/block-view.page')),
    },
    {
        path: '/blocks/:id/bookings',
        component: LoadableSafe(() => import('pages/block-detail/block-booking/block-booking.page')),
    },
    {
        path: '/blocks/:id/site-management',
        component: LoadableSafe(() => import('pages/block-detail/site-management/site-management')),
    },
    {
        path: '/blocks/:id/emails',
        component: LoadableSafe(() => import('pages/block-detail/block-sendgrids/block-sendgrids')),
    },
    ... !isHotel ? [{
        path: '/blocks/:id/team-management',
        component: LoadableSafe(() => import('pages/block-detail/ep-communication/ep-communication.page'))
    }] : [],
];

const outletOverviewRoutes: ICustomRouteProps[] = [
    {
        path: '/hotel-settings/outlets/:outletId/details',
        exact: true,
        component: LoadableSafe(() => import('pages/hotel-settings/outlet/outlet-edit/outlet-edit.page'))
    },
    {
        path: '/hotel-settings/outlets/:outletId/team-management',
        exact: true,
        component: LoadableSafe(() => import('pages/hotel-settings/outlet/outlet-overview/outlet-team-management.page.tsx'))
    },
];

const buildDashboardSubRoutes = (isHotel: boolean): ICustomRouteProps[] => {
    const blockDetailRoutes = buildBlockDetailRoutes(isHotel);

    return [
        {
            path: '/',
            exact: true,
            component: LoadableSafe(() => isHotel ?
                import('pages/block-list/blocks-list.page')
                : import('pages/ep-blocks-list/ep-blocks-list.page'))
        },
        {
            path: ArrayUtil.flatDeep(blockDetailRoutes.map(r => r.path)),
            component: LoadableSafe(() => import('pages/block-detail/block-detail')),
            routes: blockDetailRoutes,
        },
        {
            path: '/reservations/:id/view',
            component: LoadableSafe(() => import('pages/reservation/reservation-view/reservation-view'))
        },
        {
            path: '/blocks/:id/reservations/:resId/edit',
            component: LoadableSafe(() => import('pages/reservation/reservation-edit/reservation-edit'))
        },
        {
            path: '/profile/edit',
            component: LoadableSafe(() => import('pages/edit-profile/edit-profile.page'))
        },
        {
            path: '/blocks/:id/edit',
            component: LoadableSafe(() => isHotel ?
                import('pages/block-edit/block-edit.page')
                : import('pages/event-edit/event-edit.page'))
        },
        {
            path: '/blocks/:id/preview-message',
            component: LoadableSafe(() => isHotel ?
                import('pages/preview-message/preview-message.page')
                : import('pages/ep-preview-message/ep-preview-message'))
        },
        ...isHotel ? [{
            path: '/blocks/:id/preview-message-citywide',
            component: LoadableSafe(() => import('pages/ep-preview-message/ep-preview-message'))
        }] : [],
        ...isHotel ? [
            {
                path: '/invites',
                exact: true,
                component: LoadableSafe(() => import('pages/multi-block-invite-list/multi-block-invite-list.page'))
            },
            {
                path: '/invites/:id/view',
                component: LoadableSafe(() => import('pages/multi-block-invite-view/multi-block-invite-view.page'))
            },
            {
                path: '/event-planners',
                component: LoadableSafe(() => import('pages/event-planner/event-planner.page')),
            },

            // Hotel Setting
            {
                path: '/hotel-settings/users',
                component: LoadableSafe(() => import('pages/hotel-settings/uac/uac.page')),
            },
            {
                path: '/hotel-settings/information/view',
                component: LoadableSafe(() => import('pages/hotel-settings/hotel-info/hotel-info-view/hotel-info-view.page')),
            },
            {
                path: '/hotel-settings/information/edit',
                component: LoadableSafe(() => import('pages/hotel-settings/hotel-info/hotel-info-edit/hotel-info-edit.page')),
            },
            {
                path: '/hotel-settings/booking-policy',
                exact: true,
                component: LoadableSafe(() => import('pages/hotel-settings/booking-policy/booking-policy-list/booking-policy-list.page')),
            },
            {
                path: '/hotel-settings/booking-policy/:id/edit',
                component: LoadableSafe(() => import('pages/hotel-settings/booking-policy/booking-policy-edit/booking-policy-edit.page')),
            },
            {
                path: '/hotel-settings/accommodation',
                exact: true,
                component: LoadableSafe(() => import('pages/hotel-settings/accommodation/accommodation-list/accommodation-list.page')),
            },
            {
                path: '/hotel-settings/accommodation/:id/edit',
                component: LoadableSafe(() => import('pages/hotel-settings/accommodation/accommodation-edit/accommodation-edit.page')),
            },
            {
                path: '/hotel-settings/reservation',
                exact: true,
                component: LoadableSafe(() => import('pages/hotel-settings/reservation-setup/reservation-setup-view/reservation-setup-view.page'))
            },
            {
                path: '/hotel-settings/reservation/edit',
                component: LoadableSafe(() => import('pages/hotel-settings/reservation-setup/reservation-setup-edit/reservation-setup-edit.page'))
            },
            {
                path: '/hotel-settings/rate-code',
                exact: true,
                component: LoadableSafe(() => import('pages/hotel-settings/rate-code/rate-code-list/rate-code-list.page')),
            },
            {
                path: '/hotel-settings/rate-code/:id/edit',
                component: LoadableSafe(() => import('pages/hotel-settings/rate-code/rate-code-edit/rate-code-edit.page')),
            },
            {
                path: '/hotel-settings/venue',
                exact: true,
                component: LoadableSafe(() => import('pages/hotel-settings/venue/venue-list/venue-list.page'))
            },
            {
                path: '/hotel-settings/venue/:id/edit',
                component: LoadableSafe(() => import('pages/hotel-settings/venue/venue-edit/venue-edit.page'))
            },
            {
                path: '/payment-portal',
                exact: true,
                component: LoadableSafe(() => import('pages/payment-portal/pp-list/pp-list.page'))
            },
            {
                path: '/payment-portal/:id',
                exact: true,
                component: LoadableSafe(() => import('pages/payment-portal/pp-detail/pp-detail.page'))
            },
            {
                path: '/payment-portal/:id/partial',
                component: LoadableSafe(() => import('pages/payment-portal/pp-partial/pp-partial.page'))
            },
            {
                path: '/payment-portal/:id/edit',
                component: LoadableSafe(() => import('pages/payment-portal/pp-edit-invoice/pp-edit-invoice.page'))
            },
            {
                path: '/payment-portal/:id/process-status',
                component: LoadableSafe(() => import('pages/payment-portal/pp-process-status/pp-process-status.page'))
            },
            {
                path: '/pms/oauth-callback',
                component: LoadableSafe(() => import('pages/pms-oauth/pms-oauth.page'))
            },
        ] : [],
        ... !isHotel ? [
            {
                path: '/team',
                exact: true,
                component: LoadableSafe(() => import('pages/ep-team/ep-team-list/ep-team-list.page'))
            },
            {
                path: '/team/:id/edit',
                component: LoadableSafe(() => import('pages/ep-team/ep-team-edit/ep-team-edit.page'))
            },
            {
                path: '/team/:id/view',
                component: LoadableSafe(() => import('pages/ep-team/ep-team-manage/ep-team-manage.page'))
            },
            {
                path: '/my-company',
                component: LoadableSafe(() => import('pages/my-company/my-company.page'))
            },
        ] : [],
    ];
}

const buildPkgSettingsRoutes: ICustomRouteProps[] = [
    {
        path: '/pkgs/settings/shipping-delivery',
        component: LoadableSafe(() => import('pages/hotel-pkgs/settings/shipping-delivery')),
    },
    {
        path: '/pkgs/settings/product-setup',
        component: LoadableSafe(() => import('pages/hotel-pkgs/settings/product-setup')),
    },
];

const packageMagementRoutes: ICustomRouteProps[] = [
    // package management
    {
        path: ArrayUtil.flatDeep(buildPkgSettingsRoutes.map(r => r.path)),
        component: LoadableSafe(() => import('pages/hotel-pkgs/settings')),
        routes: buildPkgSettingsRoutes,
    },
    {
        path: '/pkgs/pkgs-management',
        exact: true,
        component: LoadableSafe(() => import('pages/hotel-pkgs/pkgs-management/pkgs-management-list/pkgs-management-list.page')),
    },
    {
        path: '/pkgs/pkgs-management/:pkgId/view',
        component: LoadableSafe(() => import('pages/hotel-pkgs/pkgs-management/pkgs-management-detail/pkgs-management-detail.page')),
    },
    {
        path: '/pkgs/pkgs-management/:pkgId/edit',
        component: LoadableSafe(() => import('pages/hotel-pkgs/pkgs-management/pkgs-management-edit/pkgs-management-edit.page')),
    },
    {
        path: '/pkgs/voucher-orders',
        exact: true,
        component: LoadableSafe(() => import('pages/hotel-pkgs/voucher-orders/voucher-orders-list/voucher-orders-list.page')),
    },
    {
        path: '/pkgs/voucher-orders/:orderId/view',
        component: LoadableSafe(() => import('pages/hotel-pkgs/voucher-orders/voucher-order-view/voucher-order-view.page')),
    },
    {
        path: '/pkgs/voucher-orders/:orderId/edit',
        component: LoadableSafe(() => import('pages/hotel-pkgs/voucher-orders/voucher-order-edit/voucher-order-edit.page')),
    },
    {
        path: '/pkgs/voucher-website',
        exact: true,
        component: LoadableSafe(() => import('pages/hotel-pkgs/voucher-website/voucher-website.page')),
    },

    // outlet management
    {
        path: '/hotel-settings/outlets',
        exact: true,
        component: LoadableSafe(() => import('pages/hotel-settings/outlet/outlet-list/outlet-list.page'))
    },
    {
        path: '/hotel-settings/outlets/add',
        component: LoadableSafe(() => import('pages/hotel-settings/outlet/outlet-add/outlet-add.page'))
    },
    {
        path: ArrayUtil.flatDeep(outletOverviewRoutes.map(r => r.path)),
        component: LoadableSafe(() => import('pages/hotel-settings/outlet/outlet-overview/outlet-overview.page')),
        routes: outletOverviewRoutes,
    },

    // Promo code
    {
        path: '/promo-code',
        exact: true,
        component: LoadableSafe(() => import('pages/promo-code/promo-code-list/promo-code-list.page'))
    },
    {
        path: '/promo-code/:promoCodeId/edit',
        component: LoadableSafe(() => import('pages/promo-code/promo-code-edit/promo-code-edit.page'))
    },
    {
        path: '/promo-code/:promoCodeId/detail',
        component: LoadableSafe(() => import('pages/promo-code/promo-code-detail/promo-code-detail.page'))
    },

    // Hotel Department
    {
        path: '/hotel-settings/departments',
        exact: true,
        component: LoadableSafe(() => import('pages/hotel-settings/department/department-list/departments-list.page'))
    },
    {
        path: '/hotel-settings/departments/:departmentId/edit',
        component: LoadableSafe(() => import('pages/hotel-settings/department/department-details/department-details.page'))
    },
];

export const buildDashboardRoutes = (isHotel: boolean): ICustomRouteProps => {
    const dashboardSubRoutes = [
        ...buildDashboardSubRoutes(isHotel),
        ...isHotel && OrderUtil.isVoucherEnabled() ? packageMagementRoutes : []
    ];

    return {
        path: ArrayUtil.flatDeep(dashboardSubRoutes.map(r => r.path)),
        component: LoadableSafe(() => import('components/dashboard.master.page/dashboard.masterpage')),
        routes: dashboardSubRoutes,
    }
}

const authSubRoutes: INewRouteProps[] = [
    {
        path: '/login',
        exact: true,
        strict: true,
        component: LoadableSafe(() => import('pages/auth/login/login.page'))
    },
    {
        path: '/confirm-otp/:token',
        component: LoadableSafe(() => import('pages/auth/confirm-otp/confirm-otp')),
    },
    {
        path: '/forgot-password',
        component: LoadableSafe(() => import('pages/auth/forgot-pwd/forgot-pwd.page')),
    },
    {
        path: '/reset-password/:id/:token',
        component: LoadableSafe(() => import('pages/auth/reset-pwd/reset-pwd.page')),
    },
    {
        path: '/register/:token',
        component: LoadableSafe(() => import('pages/auth/ep-register-account/ep-register-account.page'))
    },
    {
        path: '/set-password/:id/:token',
        component: LoadableSafe(() => import('pages/auth/hotel-set-pwd/hotel-set-pwd.page')),
    },
]

export const authRoutes: ICustomRouteProps = {
    path: ArrayUtil.flatDeep(authSubRoutes.map(r => r.path)),
    component: LoadableSafe(() => import('components/auth.master.page/auth.masterpage')),
    routes: authSubRoutes,
}

const guestResSubRoutes: ICustomRouteProps[] = [
    {
        path: '/',
        exact: true,
        component: LoadableSafe(() => import('guest/page/guest-home/guest-home.page'))
    },
    {
        path: '/my-reservations',
        exact: true,
        component: LoadableSafe(() => import('guest/page/guest-my-res/guest-my-res.page'))
    },
    {
        path: '/my-reservations/:id',
        component: LoadableSafe(() => import('guest/page/guest-my-res-edit/guest-my-res-edit.page'))
    },
    {
        path: '/search',
        exact: true,
        component: LoadableSafe(() => import('guest/page/guest-hotel/guest-hotel.page'))
    },
    {
        path: '/:hotel_id/search',
        exact: true,
        component: LoadableSafe(() => import('guest/page/guest-hotel/guest-hotel.page'))
    },
    {
        path: '/:hotel_id/upsell',
        exact: true,
        component: LoadableSafe(() => import('guest/page/guest-upgrade/guest-upgrade.page'))
    },
    {
        path: '/upsell',
        exact: true,
        component: LoadableSafe(() => import('guest/page/guest-upgrade/guest-upgrade.page'))
    },
    {
        path: '/steps/personal',
        exact: true,
        component: LoadableSafe(() => import('guest/page/guest-res/guest-res.page'))
    },
    {
        path: '/steps/confirm',
        exact: true,
        component: LoadableSafe(() => import('guest/page/guest-res-detail/guest-res-detail.page'))
    },
    {
        path: '/reservations/:id/page',
        exact: true,
        component: LoadableSafe(() => import('guest/page/guest-res-confirmed/guest-res-confirmed.page'))
    },
    {
        path: '/verify-token',
        exact: true,
        component: LoadableSafe(() => import('guest/page/verify-token/verify-token.page'))
    },
];

export const guestResRoutes: ICustomRouteProps = {
    path: ArrayUtil.flatDeep(guestResSubRoutes.map(r => r.path)),
    component: LoadableSafe(() => import('guest/guest.page')),
    routes: guestResSubRoutes,
}

export const voucherSubRoutes: ICustomRouteProps[] = [
    {
        path: '/',
        exact: true,
        component: Loadable({
            loader: () => import('voucher/page/voucher-home/voucher-home.page'),
            loading: NullLoading,
        }),
    },
    {
        path: '/products/:productSlug',
        exact: true,
        component: Loadable({
            loader: () => import('voucher/page/voucher-detail/voucher-detail.page'),
            loading: NullLoading,
        }),
    },
    {
        path: '/category/:categorySlug',
        exact: true,
        component: Loadable({
            loader: () => import('voucher/page/voucher-pkg/voucher-pkg-category.page'),
            loading: NullLoading,
        }),
    },
    {
        path: '/outlets/:outletSlug',
        exact: true,
        component: Loadable({
            loader: () => import('voucher/page/voucher-pkg/voucher-pkg-outlet.page'),
            loading: NullLoading,
        }),
    },
    {
        path: '/checkout',
        exact: true,
        component: Loadable({
            loader: () => import('voucher/page/voucher-checkout/voucher-checkout.page'),
            loading: NullLoading,
        }),
    },
    {
        path: '/checkout/:orderId',
        exact: true,
        component: Loadable({
            loader: () => import('voucher/page/voucher-checkout/voucher-checkout-email.page'),
            loading: NullLoading,
        }),
    },
    {
        path: '/cart',
        exact: true,
        component: Loadable({
            loader: () => import('voucher/page/voucher-cart/voucher-cart.page'),
            loading: NullLoading,
        }),
    },
    {
        path: '/search',
        exact: true,
        component: Loadable({
            loader: () => import('voucher/page/voucher-search/voucher-search.page'),
            loading: NullLoading,
        }),
    },
    {
        path: '/orders/:orderId',
        exact: true,
        component: Loadable({
            loader: () => import('voucher/page/voucher-success/voucher-success.page'),
            loading: NullLoading,
        }),
    },
    {
        path: '/verify-token',
        exact: true,
        component: Loadable({
            loader: () => import('voucher/page/voucher-verify-token/voucher-verify-token.page'),
            loading: NullLoading,
        }),
    },
];

export const voucherRoutes: ICustomRouteProps = {
    path: ArrayUtil.flatDeep(voucherSubRoutes.map(r => r.path)),
    component: LoadableSafe(() => import('voucher/page/voucher.page')),
    routes: voucherSubRoutes,
}

export const redemptionPortalSubRoutes: ICustomRouteProps[] = [
    {
        path: '/login',
        exact: true,
        component: Loadable({
            loader: () => import('redemption/page/redemption-login/redemption-login.page'),
            loading: NullLoading,
        }),
    },
    {
        path: '/profile',
        exact: true,
        component: Loadable({
            loader: () => import('redemption/page/redemption-profile/redemption-profile.page'),
            loading: NullLoading,
        }),
    },
    {
        path: '/',
        exact: true,
        component: Loadable({
            loader: () => import('redemption/page/redemption-search/redemption-search.page'),
            loading: NullLoading,
        }),
    },
    {
        path: '/voucher/:voucherCode',
        exact: true,
        component: Loadable({
            loader: () => import('redemption/page/redemption-detail/redemption-detail.page'),
            loading: NullLoading,
        }),
    }
];

export const redemptionPortalRoutes: ICustomRouteProps = {
    path: ArrayUtil.flatDeep(redemptionPortalSubRoutes.map(r => r.path)),
    component: LoadableSafe(() => import('view/redemption/page/redemption.page')),
    routes: redemptionPortalSubRoutes,
}