import React from 'react';

import styles from './dialog-confirm.scss';

import { Dialog, DialogBody, DialogFooter, DialogHeader } from '../dialog/dialog';
import { ModalModel } from 'components/modal/modal';
import { ButtonNew } from 'ui/button/button-new';
import { IDialogNotify } from 'services/modal.service';
import Container from 'typedi';
import { TranslationService } from 'services/translation.service';

const translator = Container.get(TranslationService).translator

type TProps = IDialogNotify & {
	modalModel: ModalModel;
}

export class DialogNotify extends React.Component<TProps> {
	render() {
		const { header, body, btnText, btnColor } = this.props;

		return (
			<Dialog className={styles.dialogWidth}>
				<DialogHeader>
					{header}
				</DialogHeader>
				<DialogBody className={styles.bodyText}>
					{body}
				</DialogBody>
				<DialogFooter className={styles.textAlignCenter}>
					<ButtonNew color={btnColor} onClick={this.confirmed}>{btnText || translator('Dialog.Notify.okBtn')}</ButtonNew>
				</DialogFooter>
			</Dialog>
		)
	}

	private confirmed = (): void => {
		this.props.modalModel.close();
	};
}

