exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".web-static-platform-view-ui-button-button-icon-button-icon__wrapper__dNOlJ {\n  display: flex;\n  align-items: center; }\n  .web-static-platform-view-ui-button-button-icon-button-icon__wrapper__dNOlJ .web-static-platform-view-ui-button-button-icon-button-icon__icon__3SCmz {\n    height: 14px;\n    width: 14px; }\n    .web-static-platform-view-ui-button-button-icon-button-icon__wrapper__dNOlJ .web-static-platform-view-ui-button-button-icon-button-icon__icon__3SCmz svg {\n      height: 100%;\n      width: 100%; }\n    .web-static-platform-view-ui-button-button-icon-button-icon__wrapper__dNOlJ .web-static-platform-view-ui-button-button-icon-button-icon__icon__3SCmz.web-static-platform-view-ui-button-button-icon-button-icon__disabled__2fgjW {\n      fill: white; }\n  .web-static-platform-view-ui-button-button-icon-button-icon__wrapper__dNOlJ.web-static-platform-view-ui-button-button-icon-button-icon__alignCenter__1MFUn {\n    display: inline-flex; }\n", "", {"version":3,"sources":["/home/ubuntu/projects/idemevents-web/web/static/platform/view/ui/button/button-icon/button-icon.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB,EAAE;EACtB;IACE,aAAa;IACb,YAAY,EAAE;IACd;MACE,aAAa;MACb,YAAY,EAAE;IAChB;MACE,YAAY,EAAE;EAClB;IACE,qBAAqB,EAAE","file":"button-icon.scss","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center; }\n  .wrapper .icon {\n    height: 14px;\n    width: 14px; }\n    .wrapper .icon svg {\n      height: 100%;\n      width: 100%; }\n    .wrapper .icon.disabled {\n      fill: white; }\n  .wrapper.alignCenter {\n    display: inline-flex; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "web-static-platform-view-ui-button-button-icon-button-icon__wrapper__dNOlJ",
	"icon": "web-static-platform-view-ui-button-button-icon-button-icon__icon__3SCmz",
	"disabled": "web-static-platform-view-ui-button-button-icon-button-icon__disabled__2fgjW",
	"alignCenter": "web-static-platform-view-ui-button-button-icon-button-icon__alignCenter__1MFUn"
};