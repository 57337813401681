import Container from 'typedi';
import { action, computed, IObservableArray, observable } from 'mobx';

import { Hotel, IHotelJSON, Phone, IPhoneJSON } from './hotel.model';
import { copyFields } from 'utils/object-parse';
import { DateTimeService } from 'services/date-time.service';

const dateTimeService = Container.get(DateTimeService);


export enum USER_TYPE {
	ADMIN = 0,
	HOTEL = 1,
	EVENT_PLANNER = 2,
	EVENT_STAFF = 3,
	ATTENDEE = 4,
}

export enum USER_ROLE {
	ADMIN = 'admin',
	MANAGER = 'manager',
	USER = 'user',
	SUPER_USER = 'superuser'
}

export enum BLOCK_PERMISSION {
	VIEW_BLOCK = 'block.view',
	ADD_BLOCK = 'block.add',
	EDIT_BLOCK = 'block.add',
	CANCEL_BLOCK = 'block.cancel',
	ADD_MULTI_BLOCK = 'multi_block.add',
	TEAM_ASSGIN = 'team.assign'
}

export enum RESERVATION_PERMISSION {
	VIEW_RESERVATION = 'reservation.view',
	ADD_RESERVATION = 'reservation.add',
	EDIT_RESERVATION = 'reservation.edit',
	CANCEL_RESERVATION = 'reservation.cancel',
}

export type HOTEL_SETTINGS_PERMISSION =
	'hotel.view' |
	'hotel.edit' |
	'facility.view' |
	'facility.edit' |
	'accommodation.view' |
	'accommodation.edit' |
	'reservation_setup.view' |
	'reservation_setup.edit';

export enum UAI_PERMISSION {
	USER_VIEW = 'user.view',
	USER_ADD = 'user.add',
	USER_DELETE = 'user.delete',
	USER_BLOCK_TRANSFER = 'user_block.transfer',
	MANAGER_VIEW = 'manager.view',
	MANAGER_ADD = 'manager.add',
	MANAGER_DELETE = 'manager.delete',
	MANAGER_BLOCK_TRANSFER = 'manager_block.transfer',
	ADMIN_VIEW = 'admin.view',
	ADMIN_BLOCK_TRANSFER = 'admin_block.transfer',
	ADMIN_ADD = 'admin.add',
	ADMIN_DELETE = 'admin.delete',
}

export enum ADMINISTRATIVE_PERMISSION {
	REPORT_VIEW = 'report.view',
	MANANGE_USER_VIEW = 'manage_user.view',
}

export enum EVENT_PERMISSION {
	EVENT_VIEW = 'event.view',
	EVENT_ADD = 'event.add',
	EVENT_CANCEL = 'event.cancel',
	EVENT_VIEW_PLANNER = 'ep.view',
}

export enum OUTLET_USER_ROLE {
	MANAGER = 'manager',
	USER = 'user',
	VIEW_ONLY_USER = 'view_only_user',
}

export enum OUTLET_TEAM_PERMISSION {
	TEAM_VIEW = 'team.view',
	TEAM_ADD = 'team.add',
	TEAM_EDIT = 'team.edit',
	TEAM_DELETE = 'team.delete',
}

export enum OUTLET_TEAM_MANAGE_PERMISSION {
	VIEW_ONLY_USER_VIEW = 'view_only_user.view',
	VIEW_ONLY_USER_ADD = 'view_only_user.add',
	VIEW_ONLY_USER_DELETE = 'view_only_user.delete',
	USER_VIEW = 'user.view',
	USER_ADD = 'user.add',
	USER_DELETE = 'user.delete',
	MANAGER_VIEW = 'manager.view',
	MANAGER_ADD = 'manager.add',
	MANAGER_DELETE = 'manager.delete',
}

export enum EP_USER_ROLE {
	OWNER = 'owner',
	ADMIN = 'admin',
	MANAGER = 'manager',
	USER = 'user',
}

export enum EP_TEAM_PERMISSION {
	TEAM_VIEW = 'team.view',
	TEAM_ADD = 'team.add',
	TEAM_EDIT = 'team.edit',
	TEAM_DELETE = 'team.delete',
}

export enum EP_TEAM_MANAGE_PERMISSION {
	USER_VIEW = 'user.view',
	USER_ADD = 'user.add',
	USER_DELETE = 'user.delete',
	MANAGER_VIEW = 'manager.view',
	MANAGER_ADD = 'manager.add',
	MANAGER_DELETE = 'manager.delete',
	ADMIN_VIEW = 'admin.view',
	ADMIN_ADD = 'admin.add',
	ADMIN_DELETE = 'admin.delete',
}

export type PERMISSION = BLOCK_PERMISSION | RESERVATION_PERMISSION
	| HOTEL_SETTINGS_PERMISSION | UAI_PERMISSION
	| ADMINISTRATIVE_PERMISSION | EVENT_PERMISSION
	| EP_TEAM_PERMISSION | EP_TEAM_MANAGE_PERMISSION
	| OUTLET_TEAM_MANAGE_PERMISSION;


export interface IUserJSON extends OnlyJSON<User> {
	hotels?: IHotelJSON[];
	created_at?: string;
	deleted_at?: string;
	phone: IPhoneJSON;
	type: number;
	permissions: IObservableArray<PERMISSION>
}

export interface IHotelsJSON {
	count: number;
	filter: {
		limit: number;
		page: number;
		search: string;
	};
	results?: IObservableArray<Hotel>;
}

export type EP = {
	last_name: string;
	first_name: string;
	phone?: IPhoneJSON | null;
	company_name: string;
	email: string;
}

export class User {
	public id: string;
	@observable public name: string = '';
	@observable public first_name: string = '';
	@observable public last_name: string = '';
	@observable public readonly type: USER_TYPE = USER_TYPE.ATTENDEE;
	@observable public avatar_url: string = '';
	@observable public email: string = '';
	@observable public hotel_id?: string;
	@observable public company: string = '';

	public hotels: IObservableArray<Hotel> = observable.array();
	@observable public created_at?: Date;
	@observable public deleted_at?: Date;
	@observable public block_count: number = 0;
	@observable public role: USER_ROLE = USER_ROLE.USER;
	@observable public phone_contact?: string;
	@observable public account_status: number = 0;
	@observable public phone!: Phone;
	public permissions: IObservableArray<PERMISSION> = observable.array();

	intercom_hash = '';

	@computed public get DELETE_PERMISSION(): PERMISSION[] {
		if (this.role) {
			return {
				[USER_ROLE.USER]: [UAI_PERMISSION.USER_DELETE],
				[USER_ROLE.MANAGER]: [UAI_PERMISSION.MANAGER_DELETE],
				[USER_ROLE.ADMIN]: [UAI_PERMISSION.ADMIN_DELETE],
				[USER_ROLE.SUPER_USER]: [UAI_PERMISSION.ADMIN_DELETE]
			}[this.role]
		}
		return [UAI_PERMISSION.ADMIN_DELETE];
	}

	@computed public get TRANSFER_BLOCK_PERMISSION(): PERMISSION[] {
		if (this.role) {
			return {
				[USER_ROLE.USER]: [UAI_PERMISSION.USER_BLOCK_TRANSFER],
				[USER_ROLE.MANAGER]: [UAI_PERMISSION.MANAGER_BLOCK_TRANSFER],
				[USER_ROLE.ADMIN]: [UAI_PERMISSION.ADMIN_BLOCK_TRANSFER],
				[USER_ROLE.SUPER_USER]: [UAI_PERMISSION.ADMIN_BLOCK_TRANSFER]
			}[this.role]
		}
		return [UAI_PERMISSION.ADMIN_BLOCK_TRANSFER];
	}

	@computed public get ADD_PERMISSION(): PERMISSION[] {
		if (this.role) {
			return {
				[USER_ROLE.USER]: [UAI_PERMISSION.USER_ADD],
				[USER_ROLE.MANAGER]: [UAI_PERMISSION.MANAGER_ADD],
				[USER_ROLE.ADMIN]: [UAI_PERMISSION.ADMIN_ADD],
				[USER_ROLE.SUPER_USER]: [UAI_PERMISSION.ADMIN_ADD]
			}[this.role]
		}
		return [UAI_PERMISSION.ADMIN_ADD];
	}

	@computed public get hasAddEventPermission(): boolean {
		return this.permissions.indexOf(BLOCK_PERMISSION.ADD_MULTI_BLOCK) > -1;
	}

	@computed public get hasEditReservationSetupPermission(): boolean {
		return this.permissions.indexOf('reservation_setup.edit') > -1;
	}

	@computed public get hasManageUserViewPermission(): boolean {
		return this.permissions.indexOf(ADMINISTRATIVE_PERMISSION.MANANGE_USER_VIEW) > -1;
	}

	constructor(id: string) {
		this.id = id;
	}

	@action.bound
	public setDeletedAt(deletedAt?: Date): void {
		this.deleted_at = deletedAt;
	}

	@action.bound
	public setBlockCount(blockCount: number): void {
		this.block_count = blockCount;
	}

	@action.bound
	public fromJSON(user: IUserJSON): this {
		const { type, created_at, deleted_at, hotels, permissions, phone, ...rest } = user;
		copyFields<User>(this, rest);
		if (created_at) {
			this.created_at = dateTimeService.buildDate(created_at);
		}
		if (deleted_at) {
			this.deleted_at = dateTimeService.buildDate(deleted_at);
		}
		if (hotels && hotels.length) {
			this.hotels.replace(hotels.map(h => new Hotel(h.id).fromJSON(h)));
		}
		if (permissions && permissions.length) {
			this.permissions.replace(permissions);
		}
		this.phone = new Phone(phone);
		return this;
	}

}