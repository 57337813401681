export const USER_EXP = {
    TABLE: {
        HOTEL_BLOCK_LIST: {
            PAGE: 'USER_EXP/TABLE/HOTEL_BLOCK_LIST/PAGE',
            STATUS: 'USER_EXP/TABLE/HOTEL_BLOCK_LIST/STATUS',
            HAS_DATE_FILTER: 'USER_EXP/TABLE/HOTEL_BLOCK_LIST/HAS_DATE_FILTER'
        },
        BLOCK_BOOKINGS_LIST: {
            PAGE: 'USER_EXP/TABLE/BLOCK_BOOKINGS_LIST/PAGE',
        },
        EVENT_PLANNER_LIST: {
            PAGE: 'USER_EXP/TABLE/EVENT_PLANNER_LIST/PAGE',
            STATUS: 'USER_EXP/TABLE/EVENT_PLANNER_LIST/STATUS',
        },
        EP_BLOCK_LIST: {
            PAGE: 'USER_EXP/TABLE/EP_BLOCK_LIST/PAGE',
            STATUS: 'USER_EXP/TABLE/EP_BLOCK_LIST/STATUS',
        },
        PAYMENT_PORTAL: {
            PAGE: 'USER_EXP/TABLE/PAYMENT_PORTAL/PAGE',
            STATUS: 'USER_EXP/TABLE/PAYMENT_PORTAL/STATUS',
            HAS_DATE_FILTER: 'USER_EXP/TABLE/PAYMENT_PORTAL/HAS_DATE_FILTER',
        },
        BOOKING_POLICY_LIST: {
            PAGE: 'USER_EXP/TABLE/BOOKING_POLICY_LIST/PAGE',
        },
        RATE_CODE_LIST: {
            PAGE: 'USER_EXP/TABLE/RATE_CODE_LIST/PAGE',
        },
        MULT_BLOCK_INVITE: {
            PAGE: 'USER_EXP/TABLE/MULT_BLOCK_INVITE/PAGE',
        },
        EP_TEAM_LIST: {
            PAGE: 'USER_EXP/TABLE/EP_TEAM_LIST/PAGE',
        },
        ACCOMMODATION_LIST: {
            PAGE: 'USER_EXP/TABLE/ACCOMMODATION_LIST/PAGE',
        },
        VENUE_LIST: {
            PAGE: 'USER_EXP/TABLE/VENUE_LIST/PAGE',
        },
        IMPORT_ROOMING_LIST: {
            PAGE: 'USER_EXP/TABLE/IMPORT_ROOMING_LIST/PAGE',
        },
        SENDGRIDS_LIST: {
            PAGE: 'USER_EXP/TABLE/SENDGRIDS_LIST/PAGE',
            STATUS: 'USER_EXP/TABLE/SENDGRIDS_LIST/STATUS',
        },
        PKGS_MANAGEMENT: {
            PAGE: 'USER_EXP/TABLE/PKGS_MANAGEMENT/PAGE',
            STATUS: 'USER_EXP/TABLE/PKGS_MANAGEMENT/STATUS',
            CATEGORY: 'USER_EXP/TABLE/PKGS_MANAGEMENT/CATEGORY',
        },
        VOUCHER_ORDERS: {
            PAGE: 'USER_EXP/TABLE/VOUCHER_ORDERS/PAGE',
            OUTLET: 'USER_EXP/TABLE/VOUCHER_ORDERS/OUTLET',
            STATUS: 'USER_EXP/TABLE/VOUCHER_ORDERS/STATUS',
            HAS_DATE_FILTER: 'USER_EXP/TABLE/VOUCHER_ORDERS/HAS_DATE_FILTER',
        },
        OUTLET: {
            PAGE: 'USER_EXP/TABLE/OUTLET/PAGE',
        },
        PROMO_CODE: {
            PAGE: 'USER_EXP/TABLE/PROMO_CODE/PAGE',
            STATUS: 'USER_EXP/TABLE/PROMO_CODE/STATUS',
        },
        DEPARTMENT: {
            PAGE: 'USER_EXP/TABLE/DEPARTMENT/PAGE',
        },
        VOUCHER_CODE: {
            PAGE: 'USER_EXP/TABLE/VOUCHER_CODE/PAGE',
        }
    },

    LINK: {
        EDIT_BLOCK: 'USER_EXP/LINK/EDIT_BLOCK',
    }
}


export const VOUCHER_CART = 'IDEM_VOUCHER_CART';
export const VOUCHER_ACCESS_KEY = 'VOUCHER_ACCESS_KEY';
export const RESERVATION_ACCESS_KEY = 'RESERVATION_ACCESS_KEY';