import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

class ScrollToTopComponent extends React.Component<RouteComponentProps> {
    componentDidUpdate(prevProps: RouteComponentProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        const { children } = this.props
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        )
    }
}

export const ScrollToTop = withRouter(ScrollToTopComponent)