// Pls put image to idemevents-web/web/static/img
const imagePath = process.env.IMAGE_PATH;

export const Stress = imagePath + 'stress.png';
export const Logo = imagePath + 'groupSync-logo.png';
export const LogoWhite = imagePath + 'groups360-logo-white.png';
export const ImagePlaceHolder = imagePath + 'image-placeholder.png';
export const IrmlSuccess = imagePath + 'irml-success.png';

export const Copy = imagePath + 'hotel/copy.png';
export const InviteMail = imagePath + 'email/invite-mail.png';
export const HotelGraphic = imagePath + 'hotel-graphic.png';

export const imgCard = imagePath + 'icons/icon-card.png';
export const iconReservationConfirmed = imagePath + 'icons/confirmed-icon.png';

export const Facebook = imagePath + 'social/facebook.png';
export const Instagram = imagePath + 'social/instagram.png';
export const LinkedIn = imagePath + 'social/linkedin.png';
export const Twitter = imagePath + 'social/twitter.png';
export const Youtube = imagePath + 'social/youtube.png';

export const GuestEmailPng = imagePath + 'reservation/guest/email.png';
export const GuestDownload = imagePath + 'reservation/guest/download.png';

export const VoucherBackgroundJpg = imagePath + 'voucher-background.jpg';
