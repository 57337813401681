import { action, observable, IObservableArray } from 'mobx';
import * as Sentry from '@sentry/react';

import { copyFields } from 'utils/object-parse';
import { Accommodation } from './accommodation.model';
import { Address, TIdName } from './common.model';
import { USER_ROLE } from './user.model';
import { PAYMENT_PROVIDER } from 'constants/pages';
import { THotelCategory } from 'services/hotel.service';

export enum HOTEL_PROVIDER_ENUM {
	EXTERNAL = 'external',
	IDEM = 'idem',
	UNSET = '',
}

export interface IHotelJSON extends OnlyJSON<Hotel> {
	phone: IPhoneJSON;
	accommodations: Accommodation[];
	fax: IPhoneJSON;
}

export interface IPhoneJSON extends OnlyJSON<Phone> {
}

export class Phone {
	@observable cc = '';
	@observable contact = '';
	@observable country_code = '';

	constructor(phone?: IPhoneJSON | null) {
		if (phone && phone.contact) {
			const { cc, contact, country_code } = phone;
			this.contact = contact.replace('+', '').toLowerCase();

			try {
				this.cc = cc.replace('+', '').toLowerCase();
				this.country_code = country_code.toLowerCase();
			} catch (e) {
				Sentry.captureException(e);
			}
		}
	}

	getPhoneString(): string {
		const { cc, contact } = this;
		if (!(cc + contact).trim()) return '';
		const contactStr = contact.includes('+') ? cc + contact : `+${cc} ${contact}`;
		return contactStr;
	}

	buildObject(): IPhoneJSON | null {
		const { cc, contact, country_code } = this;
		if (contact && cc) {
			return { cc, country_code, contact };
		}

		return null;
	}
}

type TRes_Setting = {
	direct_payment_enabled: boolean;
	payment_provider: PAYMENT_PROVIDER;
	currency: string;
}

export type TPartnerHotel = TIdName & {
	provider: string;
}

export class Hotel {
	id: string;
	@observable name: string = '';
	@observable logo_url: string = '';
	@observable transfer_detail: string = '';
	@observable website: string = '';
	@observable phone!: Phone;
	@observable sub_name!: string;
	@observable city: string = '';
	@observable account_manager_email: string = '';
	@observable account_manager_name: string = '';
	@observable status: string = HOTEL_INFO_STATUS.UNASSIGNED;
	@observable preferred: boolean = false;
	@observable promoted: boolean = false;
	@observable is_pms: boolean = false;
	@observable property_provider: string = '';
	@observable property_code: string = '';
	@observable description: string = '';
	@observable loyalty_url: string = '';
	@observable loyalty_club: string = '';
	reservation_emails: string[] = [];
	fax!: Phone;
	email: string = '';
	address!: Address;
	notice_message = '';
	reservation_setting!: TRes_Setting;
	categories: THotelCategory[] = [];
	partners: TPartnerHotel[] = [];
	notification_shipping_emails: string[] = [];
	physical_delivery_enabled = false;
	delivery_option = 'single';

	accommodations: IObservableArray<Accommodation> = observable.array();
	sub_description = '';

	@observable action?: string = '';
	@observable role?: USER_ROLE;
	@observable provider?: HOTEL_PROVIDER_ENUM;

	constructor(id: string) {
		this.id = id;
	}

	@action.bound
	fromJSON(j: IHotelJSON): this {
		const { phone, accommodations, fax, ...rest } = j;
		copyFields<Hotel>(this, rest);

		this.phone = new Phone(phone);
		this.fax = new Phone(fax);
		this.accommodations.replace(accommodations);

		return this;
	}

	@action.bound
	setName(name: string): void {
		this.name = name;
	}

	@action.bound
	setProvider(p: HOTEL_PROVIDER_ENUM): void {
		this.provider = p;
	}

	@action.bound
	setAccountManagerName(value: string): void {
		this.account_manager_name = value;
	}

	@action.bound
	setAccountManagerEmail(value: string): void {
		this.account_manager_email = value;
	}

	toPartnerJSON(): IAssignPartnerHotelData {
		return {
			id: this.id,
			name: this.name,
			account_manager_name: this.account_manager_name,
			account_manager_email: this.account_manager_email,
			preferred: this.preferred,
			promoted: this.promoted,
			provider: this.provider || '',
			sub_description: this.sub_description
		}
	}

	toAssignedJSON(): IAssignHotelData {
		return {
			id: this.id,
			name: this.name,
			account_manager_name: this.account_manager_name,
			account_manager_email: this.account_manager_email,
			sub_name: this.sub_name,
			phone: this.phone.buildObject() || undefined,
			city: this.city,
			preferred: this.preferred,
			promoted: this.promoted,
			provider: this.provider || HOTEL_PROVIDER_ENUM.UNSET,
			sub_description: this.sub_description
		}
	}

	setDeliveryOption = (value: string) => {
		this.delivery_option = value;
	}

	setNotificationSettingsEmails = (emails: string[]) => {
		this.notification_shipping_emails = emails;
	}

	setPhysicalDeliveryEnabled = (value: boolean) => {
		this.physical_delivery_enabled = value;
	}
}

export enum HOTEL_INFO_STATUS {
	PENDING = 'pending',
	OPEN = 'open',
	UNASSIGNED = 'unassigned',
	CANCELLED = 'cancelled',
	CLOSED = 'closed',
	REJECTED = 'rejected'
}

export interface IAssignHotelData {
	name: string;
	id: string;
	provider: HOTEL_PROVIDER_ENUM;
	account_manager_name: string;
	account_manager_email: string;
	sub_name: string;
	phone?: IPhoneJSON | null;
	city: string;
	preferred: boolean;
	promoted: boolean;
	sub_description: string;
}

export interface IAssignPartnerHotelData {
	id: string;
	name: string;
	account_manager_name: string;
	account_manager_email: string;
	preferred: boolean;
	promoted: boolean;
	provider: string;
	sub_description: string;
}
