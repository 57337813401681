export const st = {
	p12: 'padding-global--12',

	pt4: 'padding-top-global--4',
	pt8: 'padding-top-global--8',
	pt10: 'padding-top-global--10',
	pt12: 'padding-top-global--12',
	pt16: 'padding-top-global--16',
	pt20: 'padding-top-global--20',
	pt24: 'padding-top-global--24',
	pt30: 'padding-top-global--30',

	pb4: 'padding-bottom-global--4',
	pb8: 'padding-bottom-global--8',
	pb10: 'padding-bottom-global--10',
	pb12: 'padding-bottom-global--12',
	pb16: 'padding-bottom-global--16',
	pb20: 'padding-bottom-global--20',
	pb24: 'padding-bottom-global--24',
	pb30: 'padding-bottom-global--30',

	pr4: 'padding-right-global--4',
	pr8: 'padding-right-global--8',
	pr10: 'padding-right-global--10',
	pr12: 'padding-right-global--12',
	pr16: 'padding-right-global--16',
	pr20: 'padding-right-global--20',
	pr24: 'padding-right-global--24',
	pr30: 'padding-right-global--30',

	pl4: 'padding-left-global--4',
	pl8: 'padding-left-global--8',
	pl10: 'padding-left-global--10',
	pl12: 'padding-left-global--12',
	pl16: 'padding-left-global--16',
	pl20: 'padding-left-global--20',
	pl24: 'padding-left-global--24',
	pl30: 'padding-left-global--30',

	mt4: 'margin-top-global--4',
	mt8: 'margin-top-global--8',
	mt10: 'margin-top-global--10',
	mt12: 'margin-top-global--12',
	mt16: 'margin-top-global--16',
	mt20: 'margin-top-global--20',
	mt24: 'margin-top-global--24',
	mt30: 'margin-top-global--30',

	mb4: 'margin-bottom-global--4',
	mb8: 'margin-bottom-global--8',
	mb10: 'margin-bottom-global--10',
	mb12: 'margin-bottom-global--12',
	mb16: 'margin-bottom-global--16',
	mb20: 'margin-bottom-global--20',
	mb24: 'margin-bottom-global--24',
	mb30: 'margin-bottom-global--30',

	font9: 'font-size-global--9',
	font11: 'font-size-global--11',
	font12: 'font-size-global--12',
	font13: 'font-size-global--13',
	font15: 'font-size-global--15',
	font16: 'font-size-global--16',
	font17: 'font-size-global--17',
	font18: 'font-size-global--18',
	font20: 'font-size-global--20',
	font22: 'font-size-global--22',

	fontW100: 'font-weight-global--100',
	fontW200: 'font-weight-global--200',
	fontW300: 'font-weight-global--300',
	fontW400: 'font-weight-global--400',
	fontW500: 'font-weight-global--500',
	fontW600: 'font-weight-global--600',
	fontW700: 'font-weight-global--700',

	floatL: 'float-global--left',
	floatR: 'float-global--right',

	textAlignL: 'text-align-global--left',
	textAlignR: 'text-align-global--right',

	w100: 'width-global--100',
	w50: 'width-global--50',

	pointer: 'cursor-global--pointer',
}
