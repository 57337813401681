import React from 'react';

import styles from './error-template.scss';

import { Logo } from 'assets/img';
import { TextLink } from 'ui/text/text-link/text-link';

type TProps = {
    code: string;
    message: string;
    subMessages?: JSX.Element;
    onGoBack: () => void;
    onGoToHomePage?: () => void;
}

export default class ErrorTemplate extends React.PureComponent<TProps> {

    render(): React.ReactNode {
        const { code, message, subMessages, onGoBack, onGoToHomePage } = this.props;

        return (
            <div className={styles.wrapper}>
                <div className={styles.inner}>
                    <div className={styles.content}>
                        <div className={styles.level1}>
                            <div className={styles.level2}>
                                <img src={Logo} />
                            </div>
                            <div className={styles.message}>
                                <div className={styles.text1}>{message}</div>
                                {subMessages &&
                                    <div className={styles.text2}>
                                        {subMessages}
                                    </div>
                                }
                            </div>
                            {onGoToHomePage
                                ?
                                <div className={styles.btn}>
                                    <button onClick={onGoBack}>Go back</button>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Or&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <button onClick={onGoToHomePage}>Go to Home page</button>
                                </div>
                                :
                                <div className={styles.btn}><button onClick={onGoBack}>Go back</button> </div>
                            }
                            <div>{code} Error</div>
                            <hr />
                            <div className={styles.link}>
                                <TextLink href={PRIVACY_URL}>Privacy Policy</TextLink>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<TextLink href={HELP_CENTER}>Help</TextLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}