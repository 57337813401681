exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n", "", {"version":3,"sources":[],"names":[],"mappings":"","file":"style-const.scss","sourceRoot":""}]);

// exports
exports.locals = {
	"colorBlack": "#333",
	"colorWhite": "#ffffff",
	"colorDarkRed": "#030303",
	"colorDarkGray": "#777",
	"colorMango": "#f6ab2f",
	"colorTomato": "#e6492d",
	"colorGreen": "#34aa44",
	"colorGray": "#9b9b9b",
	"chartColor1": "#FF6C0C",
	"chartColor3": "#B00020",
	"colorIdemBlue": "#0095ff",
	"colorAzure2": "#008ef2",
	"colorIdemGreen": "#0cba80",
	"colorIdemGray": "#aaaaaa",
	"colorIdemGray2": "#a6a6a6",
	"colorIdemRed": "#fc665c",
	"colorIdemLightRed": "#ffeae9",
	"colorBodyBlack": "#4a4a4a",
	"colorBrownGray": "#a2a2a2",
	"colorIdemDarkBlue": "#0066cd",
	"colorIdemLightBlue": "#e9f1f8",
	"colorIdemLightMango": "#fef6ea",
	"colorIdemLightGreen": "#cef1e6",
	"colorIdemOrange": "#ff7d00",
	"colorIdemLightOrange": "#ffe1c4",
	"colorIdemPurple": "#a064bb",
	"colorIdemLightPurple": "#f2e4ff",
	"idemWebsiteBg": "#f6f7f8",
	"idemWebsiteLine": "#e0e0e0"
};