class SVGIconClass {
	Adult = require('./svg/adult.svg').default;
	ArrowLeft = require('./svg/left-arrow.svg').default;
	ArrowRight = require('./svg/right-arrow.svg').default;
	Bed = require('./svg/bed.svg').default;
	Beds = require('./svg/beds.svg').default;
	Calendar = require('./svg/calendar.svg').default;
	CalendarOutline = require('./svg/calendar-outline.svg').default;
	CaretBot = require('./svg/caret-bottom.svg').default;
	CaretRight = require('./svg/caret-right.svg').default;
	CaretTop = require('./svg/caret-top.svg').default;
	Check = require('./svg/check.svg').default;
	ChevronBottom = require('./svg/chevron-bottom.svg').default;
	ChevronLeft = require('./svg/chevron-left.svg').default;
	ChevronRight = require('./svg/chevron-right.svg').default;
	ChevronTop = require('./svg/chevron-top.svg').default;
	Children = require('./svg/children.svg').default;
	Close = require('./svg/close.svg').default;
	CloudDown = require('./svg/cloud-down.svg').default;
	CloudUp = require('./svg/cloud-up.svg').default;
	Contracted = require('./svg/contracted.svg').default;
	Cross = require('./svg/cross.svg').default;
	Distance = require('./svg/distance.svg').default;
	Double = require('./svg/double-bed.svg').default;
	Elevator = require('./svg/elevator.svg').default;
	Ellipses = require('./svg/ellipses.svg').default;
	Eye = require('./svg/eye.svg').default;
	EyeSlash = require('./svg/eye-slash.svg').default;
	Favorite = require('./svg/favorite.svg').default;
	Guest = require('./svg/guest.svg').default;
	Info = require('./svg/info.svg').default;
	Link = require('./svg/link.svg').default;
	Help = require('./svg/help.svg').default;
	Home = require('./svg/home.svg').default;
	Pencil = require('./svg/pencil.svg').default;
	People = require('./svg/people.svg').default;
	Person = require('./svg/person.svg').default;
	PinArounded = require('./svg/pin-rounded.svg').default;
	Plus = require('./svg/plus.svg').default;
	ProfileRound = require('./svg/profile.svg').default;
	Quad = require('./svg/quad-bed.svg').default;
	Question = require('./svg/question.svg').default;
	Question2 = require('./svg/question2.svg').default;
	RoomSize = require('./svg/room-size.svg').default;
	Search = require('./svg/search.svg').default;
	Single = require('./svg/single.svg').default;
	Spinner = require('./svg/spinner.svg').default;
	Standing = require('./svg/standing.svg').default;
	Star = require('./svg/star.svg').default;
	Trash = require('./svg/trash.svg').default;
	Triple = require('./svg/triple-bed.svg').default;
	Twin = require('./svg/twin.svg').default;
	UserRounded = require('./svg/user-circle.svg').default;
	Users = require('./svg/users.svg').default;
	Wallet = require('./svg/wallet.svg').default;
	Billing = require('./svg/billing.svg').default;
	Hambuger = require('./svg/hambuger.svg').default;
	TemplateExist = require('./svg/template-exist.svg').default;
	TemplateNew = require('./svg/template-new.svg').default;
	CircleCheck = require('./svg/circle-check.svg').default;
	CircleCancel = require('./svg/circle-cancel.svg').default;
	CircleCancelTransparent = require('./svg/circle-cancel-transparent.svg').default;

	Alert = require('./svg/alert.svg').default;
	InvalidData = require('./svg/invalid-data.svg').default;

	// GRW
	GuestStarActive = require('./svg/guest/star-active.svg').default;
	GuestStarInactive = require('./svg/guest/star-inactive.svg').default;
	GuestCalendar = require('./svg/guest/calendar.svg').default;
	GuestCoin = require('./svg/guest/coin.svg').default;
	GuestPerson = require('./svg/guest/person.svg').default;
	GuestNight = require('./svg/guest/night.svg').default;
	GuestNotificationError = require('./svg/guest/notification-error.svg').default;
	GuestNotificationPlus = require('./svg/guest/notification-plus.svg').default;
	GuestNotificationInfo = require('./svg/guest/notification-info.svg').default;
	GuestCompass = require('./svg/guest/compass.svg').default;
	GuestVerifyFailed = require('./svg/guest/verify-failed.svg').default;
	GuestEmail = require('./svg/guest/email.svg').default;
	GuestBed = require('./svg/guest/bed.svg').default;
	GuestFacebook = require('./svg/guest/facebook.svg').default;
	GuestInstagram = require('./svg/guest/instagram.svg').default;
	GuestLinkedIn = require('./svg/guest/linkedIn.svg').default;
	GuestTwitter = require('./svg/guest/twitter.svg').default;
	GuestYoutube = require('./svg/guest/youtube.svg').default;

	// Amenities
	AmenityPlaceHolder = require('./svg/amenity/00-placeholder.svg').default;
	Amenity01 = require('./svg/amenity/01-aircon.svg').default;
	Amenity02 = require('./svg/amenity/02-alarm-clock.svg').default;
	Amenity03 = require('./svg/amenity/03-bath-robe.svg').default;
	Amenity04 = require('./svg/amenity/04-cable-tv.svg').default;
	Amenity05 = require('./svg/amenity/05-coffee-maker.svg').default;
	Amenity06 = require('./svg/amenity/06-complimentary-bottled-water.svg').default;
	Amenity07 = require('./svg/amenity/07-complimentary-breakfast.svg').default;
	Amenity08 = require('./svg/amenity/08-complimentary-coffee-tea.svg').default;
	Amenity09 = require('./svg/amenity/09-complimentary-toiletres.svg').default;
	Amenity10 = require('./svg/amenity/10-hair-dryer.svg').default;
	Amenity11 = require('./svg/amenity/11-hot-tub.svg').default;
	Amenity12 = require('./svg/amenity/12-iron.svg').default;
	Amenity13 = require('./svg/amenity/13-kichenette.svg').default;
	Amenity14 = require('./svg/amenity/14-luxury-bedding.svg').default;
	Amenity15 = require('./svg/amenity/15-microwave.svg').default;
	Amenity16 = require('./svg/amenity/16-mini-fridge.svg').default;
	Amenity17 = require('./svg/amenity/17-mobile-usb-charging.svg').default;
	Amenity18 = require('./svg/amenity/18-nightly-turndown-service.svg').default;
	Amenity19 = require('./svg/amenity/19-room-service.svg').default;
	Amenity20 = require('./svg/amenity/20-safe-locker.svg').default;
	Amenity21 = require('./svg/amenity/21-table-lamp.svg').default;
	Amenity22 = require('./svg/amenity/22-television-set.svg').default;
	Amenity23 = require('./svg/amenity/23-wifi.svg').default;
	Amenity24 = require('./svg/amenity/24-writing-table-with-chair.svg').default;

	// Irml
	IrmlBigCheck = require('./svg/irml/big-check.svg').default;

	// Sidebar
	SideBarBedSmall = require('./svg/side-bar/bed-small.svg').default;
	SideBarNotify = require('./svg/side-bar/notify.svg').default;
	SideBarCard = require('./svg/side-bar/card.svg').default;
	SideBarBriefcase = require('./svg/side-bar/briefcase.svg').default;
	SideBarPackages = require('./svg/side-bar/packages.svg').default;
	SideBarPromoCode = require('./svg/side-bar/promo-code.svg').default;
}

class SVGImageClass {
	Wavy = require('./svg/wavy.svg').default;
}

export const SVGIcon = new SVGIconClass() as { [key in keyof SVGIconClass]: React.SFC<React.SVGProps<SVGElement>> };
export const SVGImage = new SVGImageClass() as { [key in keyof SVGImageClass]: React.SFC<React.SVGProps<SVGElement>> };
