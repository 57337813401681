import { User } from 'models/user.model';
import Container, { Service } from 'typedi';
import { APIService, IResponseData, PREFIX } from './api.service';
import { DocumentService } from './document.service';

const documentService = Container.get(DocumentService)
const REQUIRED_ATTRS = ['hotel_id', 'block_id', 'reservation_id', 'payment_id', 'promo_code_id', 'pkgs_management_id', 'voucher_order_id', 'accommodation_id', 'booking_policy_id', 'rate_code_id', 'venue_id', 'outlet_id']

const apiService = Container.get(APIService)

export interface IIntercomContact {
    name?: string;
    email?: string;
    custom_attributes?: ICustomAttributes;
}

export interface ICustomAttributes {
    hotel_id?: string | null;
    block_id?: string | null;
    reservation_id?: string | null;
    payment_id?: string | null;
    promo_code_id?: string | null;
    pkgs_management_id?: string | null;
    voucher_order_id?: string | null;
    accommodation_id?: string | null;
    booking_policy_id?: string | null;
    rate_code_id?: string | null;
    venue_id?: string | null;
    outlet_id?: string | null;
    [key: string]: any;
}

export type ContentType = 'RoomBlock' | 'Reservation' | 'TransactionGroup' | 'PromoCode' | 'Package' | 'Order' | 'Accommodation' | 'BookingPolicy' | 'HotelRatePlan' | 'Venue' | 'Vendor'

@Service()
export class IntercomService {
    async loadIntercom(user?: User) {

        const appId = INTERCOM_APP_ID;
        const wd = (window as any);

        wd.intercomSettings = {
            app_id: appId,
            dashboard: documentService.getFirstDomain(),
            user_hash: INTERCOM_USER_HASH
        };

        // intercom restrict data of email
        if (user) {
            wd.intercomSettings['name'] = user.name;
            wd.intercomSettings['email'] = user.email;
            wd.intercomSettings['user_id'] = INTERCOM_USER_ID;

            try {

                // get intercom's custom attributes
                const custom_attributes = await this.getContactCustomAttributes()

                // check current hotel, required fields and null value
                if (custom_attributes?.hotel_id !== user.hotel_id
                    || !this.isValidCustomAttributes(custom_attributes)) throw new Error()

            } catch (err) {
                // add default custom attributes to settings
                wd.intercomSettings = {
                    ...wd.intercomSettings,
                    ...INTERCOM_CUSTOM_ATTRS
                }
            }

        } else {
            if (documentService.getVariableValue('email')) {
                wd.intercomSettings['email'] = documentService.getVariableValue('email');
            } else {
                wd.intercomSettings['user_id'] = INTERCOM_USER_ID;
            }
        }

        const initial = () => {
            const ic = wd.Intercom;

            if (typeof ic === 'function') {
                ic('reattach_activator');
                ic('update', wd.intercomSettings);
            } else {
                const i: any = function () {
                    i.c(arguments)
                };
                i.q = [];
                i.c = function (args: any) {
                    i.q.push(args)
                };

                wd.Intercom = i;

                if (wd.attachEvent) {
                    wd.attachEvent('onload', loadScript());
                } else {
                    wd.addEventListener('load', loadScript(), false);
                }
            }
        }

        const loadScript = () => {
            const scriptTag = documentService.createScriptTag({ src: `https://widget.intercom.io/widget/${appId}` });
            scriptTag.async = true;
            document.getElementsByTagName('head')[0].appendChild(scriptTag);
        }

        initial();
    }

    update(data: ICustomAttributes) {
        const w = (window as any);
        const ic = w.Intercom;

        w.intercomSettings = {
            ...w.intercomSettings,
            ...data
        }

        if (typeof ic === 'function') {
            ic('update', w.intercomSettings);
        }
    }

    getContactCustomAttributes = async (id: string = 'me'): Promise<ICustomAttributes> => {

        const res = await apiService.get(`${PREFIX.USERS}${id}/intercom-contact`)

        const handler = (json: IResponseData<IIntercomContact>): ICustomAttributes => {
            if (json.code === 'OK') {
                return json.data.custom_attributes || {}
            }
            return {};
        }

        return apiService.parseResponseData(res, handler, {});
    }

    isValidCustomAttributes(attributes: ICustomAttributes): boolean {
        return REQUIRED_ATTRS.every(key => attributes[key])
    }
}
