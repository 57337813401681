import './public-path';
import '@babel/polyfill';
import 'proxy-polyfill';
import 'isomorphic-fetch';
import 'reflect-metadata';
import 'react-dates/initialize';
import { configure } from 'mobx';
import { UI } from './app';

configure({
	enforceActions: 'observed',
});

// Start app
UI();
