import Container, { Service } from 'typedi';
import moment, { isMoment } from 'moment';
import momentZone from 'moment-timezone';

import { DocumentService } from './document.service';

export const enum DATE_TYPE {
	CHECK_IN = 'CHECK_IN',
	CHECK_OUT = 'CHECK_OUT',
	PRE = 'PRE',
	POST = 'POST'
};

export const DATE_FORMAT: { [key: string]: Intl.DateTimeFormatOptions } = {
	DEFAULT: { year: 'numeric', month: 'short', day: 'numeric', minute: 'numeric', hour: 'numeric' },
	DEFAULT_NOTIME: { year: 'numeric', month: 'short', day: 'numeric' },
	CHART_DAY_MONTH: { day: 'numeric', month: 'short' },
	WEEKDAY_SHORT: { weekday: 'short' },
	WEEKDAY_DAY_MONTH: { weekday: 'short', day: 'numeric', month: 'numeric' },
	ISO_DATE: { day: 'numeric' },
};

export const enum DATE_FORMAT_MOMENT {
	ISO_DATE = 'YYYY-MM-DD',
	CUSTOM_DATE = 'DD MMM YYYY',
	LOCALE = 'll',
	DATE_WITH_SHORT_DAY = 'ddd DD MMM YYYY',
	DATE_WITH_SHORT_DAY_AFTER = 'DD MMM YYYY, ddd',
	DATE_WITH_LONG_DAY_AFTER = 'DD MMM YYYY, ddd',
	DATE_TIME = 'DD MMM YYYY, hh:mm a',
	MMDDYYYY_SLASH = 'MM/DD/YYYY',
	DDMMYYYY_SLASH = 'DD/MM/YYYY',
	YYYYMMDD_SLASH = 'YYYY/MM/DD',
	CUSTOM_DATE_NO_YEAR = 'DD MMM',
}

const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

type TAdjustableDate = {
	date: Date,
	amount: number,
	unit: moment.unitOfTime.Base 
	action: 'add' | 'subtract'
}

@Service()
export class DateTimeService {
	private documentService = Container.get(DocumentService);

	public formatDate(d: Date, format: Intl.DateTimeFormatOptions, locale?: string): string {
		return d.toLocaleDateString(locale, format);
	}

	public formatDateMoment(d: Date, format: DATE_FORMAT_MOMENT | string): string {
		return moment(d).format(format);
	}

	public formatCustomDate(d: Date, format: string): string {
		return moment(d).format(format);
	}

	public diff2Dates(d1: Date, d2: Date): number {
		const moment1 = moment(d1).startOf('day');
		const moment2 = moment(d2).startOf('day');

		return moment2.diff(moment1, 'days');
	}

	public getDateByTimeZone(): Date {
		const timezone = this.documentService.getTimeZone();
		if (timezone) {
			const currentMoment = momentZone().tz(timezone);
			return currentMoment.local(true).toDate();
		}

		return this.buildDate();
	}

	public convertDateByTimeZone(inputDate: moment.Moment | string | Date, timezone: string = this.documentService.getTimeZone()): Date {
		const momentDate = isMoment(inputDate) ? inputDate : moment(inputDate);
		const finalTimeZone = timezone || 'Asia/Singapore';

		return moment(momentDate.tz(finalTimeZone).format('YYYY-MM-DD HH:mm')).toDate();
	}

	public getDateByAmountOfDays(date: Date, days: number): Date {
		return moment(date).add(days, 'days').toDate();
	}

	public getDateByAmountOfMonths(date: Date, months: number): Date {
		return moment(date).add(months, 'months').toDate();
	}

	public getUtcOffset(): number {
		return moment().utcOffset() / 60
	}

	public getCurrentDate(): Date {
		return moment().toDate();
	}

	public getCurrentDateString(): string {
		return moment().format(DATE_FORMAT_MOMENT.CUSTOM_DATE);
	}

	public isBetween(date: Date, from: Date, to: Date): boolean {
		const dateStr = moment(date).format(DATE_FORMAT_MOMENT.ISO_DATE);
		const fromStr = moment(from).format(DATE_FORMAT_MOMENT.ISO_DATE);
		const toStr = moment(to).format(DATE_FORMAT_MOMENT.ISO_DATE);
		const momentDate = moment(dateStr);

		return momentDate.isBetween(from, to) || momentDate.isSame(fromStr) || momentDate.isSame(toStr);
	}

	public getDayName(date: Date): string {
		return DAYS_OF_WEEK[date.getDay()];
	}

	public getCustomDateString(d: Date | string, noYear?: boolean): string {
		if (!d) {
			return '';
		}
		return noYear
			? moment(d).format(DATE_FORMAT_MOMENT.CUSTOM_DATE_NO_YEAR)
			: moment(d).format(DATE_FORMAT_MOMENT.CUSTOM_DATE);
	}

	public getIsoDateString(d: Date): string {
		if (!d) {
			return '';
		}
		return moment(d).format(DATE_FORMAT_MOMENT.ISO_DATE);
	}

	public getDateWithShortDayString(d: Date | string): string {
		if (!d) {
			return '';
		}
		return moment(d).format(DATE_FORMAT_MOMENT.DATE_WITH_SHORT_DAY);
	}

	public getDateTimeString(d: Date | string): string {
		if (!d) {
			return '';
		}
		return moment(d).format(DATE_FORMAT_MOMENT.DATE_TIME);
	}

	public formatRangeDayDisplay(startDate?: Date, endDate?: Date): string {
		if (startDate && endDate) {
			const start = this.formatDateMoment(startDate, DATE_FORMAT_MOMENT.CUSTOM_DATE);
			const end = this.formatDateMoment(endDate, DATE_FORMAT_MOMENT.CUSTOM_DATE);
			return `${start} - ${end}`;
		} else {
			return '-'
		}
	}

	public getDatesString(startDate: Date, endDate: Date): string[] {
		const dates: string[] = [];
		const nights = this.diff2Dates(startDate, endDate);

		for (let i = 0; i < nights; i++) {
			const date = this.getDateByAmountOfDays(startDate, i);
			dates.push(this.getCustomDateString(date));
		}

		return dates;
	}

	buildStringValue(date: Date | null, dateFormat: string): string {
		return date ? this.formatCustomDate(date, dateFormat) : '';
	}

	buildDate(date?: string | number | Date): Date {
		return moment(date).toDate();
	}

	adjustDate({ date, amount, unit, action }: TAdjustableDate): Date {
		switch(action) {
			case 'subtract':
				return moment(date).subtract(amount, unit).toDate();
			case 'add':
				return moment(date).add(amount, unit).toDate();
		}
	}
}
