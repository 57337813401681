import React from 'react';

import styles from './dialog-confirm.scss';

import { ModalModel } from 'components/modal/modal';
import { BUTTON_COLOR, ButtonNew } from 'ui/button/button-new';
import { ButtonIcon } from 'ui/button/button-icon/button-icon';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '../dialog/dialog';
import { BoxInline } from 'ui/box/box-inline/box-inline';
import { IDialogMultiAction } from 'services/modal.service';
import Container from 'typedi';
import { TranslationService } from 'services/translation.service';

const translator = Container.get(TranslationService).translator

type TProps = IDialogMultiAction & {
	modalModel: ModalModel<string>;
}

export class DialogMultiAction extends React.Component<TProps> {

	render() {
		const { header, body, actionBtns } = this.props;

		return (
			<Dialog>
				<DialogHeader>{header}</DialogHeader>
				<DialogBody className={styles.bodyText}>{body}</DialogBody>
				<DialogFooter className={styles.customFooter}>
					<ButtonNew color={BUTTON_COLOR.CLASSIC} onClick={() => this.confirmed()} style={{ marginRight: '20px' }}>{translator("Dialog.MultiAction.cancelBtn")}</ButtonNew>
					<BoxInline>
						{actionBtns.map(btn => btn.icon ?
							<ButtonIcon key={btn.text} icon={btn.icon} color={btn.color} onClick={() => this.confirmed(btn.resolveValue)}>{btn.text}</ButtonIcon>
							:
							<ButtonNew key={btn.text} color={btn.color} onClick={() => this.confirmed(btn.resolveValue)}>{btn.text}</ButtonNew>
						)}
					</BoxInline>
				</DialogFooter>
			</Dialog>
		)
	}

	private confirmed = (resolveValue: string = ''): void => {
		this.props.modalModel.setResolveValue(resolveValue);
		this.props.modalModel.close();
	};
}

