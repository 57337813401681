import React from 'react';

import styles from './dialog-confirm.scss';

import { ModalModel } from 'components/modal/modal';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from 'dialogs/dialog/dialog';
import { BUTTON_COLOR, ButtonNew } from 'ui/button/button-new';
import { IDialogConfirm } from 'services/modal.service';
import Container from 'typedi';
import { TranslationService } from 'services/translation.service';

const translator = Container.get(TranslationService).translator

type TProps = IDialogConfirm & {
	modalModel: ModalModel<boolean>;
}

export class DialogConfirm extends React.Component<TProps> {
	render() {
		const { header, body, btnColor, btnText } = this.props;
		return (
			<Dialog className={styles.dialogWidth}>
				<DialogHeader>
					{header}
				</DialogHeader>
				<DialogBody className={styles.bodyText}>
					{body}
				</DialogBody>
				<DialogFooter className={styles.customFooter}>
					<ButtonNew color={BUTTON_COLOR.CLASSIC} onClick={this.rejected}>{translator("Dialog.Confirm.cancelBtn")}</ButtonNew>
					{btnText && <ButtonNew color={btnColor} onClick={this.confirmed}>{btnText}</ButtonNew>}
				</DialogFooter>
			</Dialog>
		)
	}

	private confirmed = (): void => {
		this.props.modalModel.setResolveValue(true);
		this.props.modalModel.close();
	};

	private rejected = (): void => {
		this.props.modalModel.setResolveValue(false);
		this.props.modalModel.close();
	};
}
