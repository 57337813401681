import React from 'react';

import { ModalModel } from 'components/modal/modal';
import { Dialog, DialogHeader, DialogBody, DialogFooter } from 'new-dialogs/dialog/dialog';
import { ButtonNew } from 'ui/button/button-new';
import { IDialogConfirm } from 'services/modal.service';
import { ButtonOutline, BUTTON_OUTLINE_COLOR } from 'ui/button/button-outline/button-outline';
import styled from 'styled-components';
import { BoxInline } from 'ui/box/box-inline/box-inline';
import Container from 'typedi';
import { TranslationService } from 'services/translation.service';

const translator = Container.get(TranslationService).translator

const DialogStyled = styled(Dialog)`

	.dialog {
		max-width: 725px;
	}

	.content {
		font-size: 13px;
        font-weight: 300;
		padding: 25px 0;
		line-height: 1.54;
	}
`

type TProps = IDialogConfirm & {
	modalModel: ModalModel<boolean>;
}

export class DialogConfirmNew extends React.Component<TProps> {
	render() {
		const { header, body, btnColor, btnText, btnCancelText = translator("Dialog.ConfirmNew.cancelBtn"), btnHeight = 30 } = this.props;
		return (
			<DialogStyled>
				<DialogHeader key="header" onCloseDialog={this.rejected}>
					{header}
				</DialogHeader>
				<DialogBody key="body">
					<div className="content">
						{body}
					</div>
				</DialogBody>
				<DialogFooter key="footer">
					<BoxInline justify="flex-end" className="buttons">
						<ButtonOutline
							onClick={this.rejected}
							color={BUTTON_OUTLINE_COLOR.CLASSIC}
							customHeight={btnHeight}>
							{btnCancelText}
						</ButtonOutline>
						{btnText &&
							<ButtonNew
								color={btnColor}
								onClick={this.confirmed}
								customHeight={btnHeight}>
								{btnText}
							</ButtonNew>}
					</BoxInline>
				</DialogFooter>
			</DialogStyled>
		)
	}

	private confirmed = (): void => {
		this.props.modalModel.setResolveValue(true);
		this.props.modalModel.close();
	};

	private rejected = (): void => {
		this.props.modalModel.setResolveValue(false);
		this.props.modalModel.close();
	};
}

