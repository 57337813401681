import Container, { Service } from 'typedi';
import { DocumentService } from './document.service';
import { TranslationService } from './translation.service';

export const EVENT_PLANNER_USER = 'EVENT_PLANNER_USER';
export const HOTEL_USER = 'HOTEL_USER';
export const GUEST_USER = 'GUEST_USER';

const translator = Container.get(TranslationService).translator

interface context {
	[EVENT_PLANNER_USER]: string;
	[HOTEL_USER]: string;
	[key: string]: string;
}

const CUT_OFF_DATE: context = {
	[HOTEL_USER]: 'Days Until Reservation Site Closure',
	[EVENT_PLANNER_USER]: 'Days Until Reservation Site Closure',
};

const START_DATE: context = {
	[HOTEL_USER]: 'Days Until Event',
	[EVENT_PLANNER_USER]: 'Days Until Event',
};

const EVENT_ATTACHMENT: context = {
	[HOTEL_USER]: 'Event',
	[EVENT_PLANNER_USER]: 'Your',
};

const HOTEL_ATTACHMENT: context = {
	[HOTEL_USER]: 'Your',
	[EVENT_PLANNER_USER]: 'Hotel',
};

const BLOCK_EVENT: context = {
	get HOTEL_USER() { return translator('Global.block') },
	get EVENT_PLANNER_USER() { return translator('Global.event') },
};

const SPEND_REVENUE: any = {

	get translator() {
		return Container.get(TranslationService).translator
	},

	get HOTEL_USER(): string {
		return SPEND_REVENUE.translator('Block.revenue')
	},

	get EVENT_PLANNER_USER(): string {
		return SPEND_REVENUE.translator('Block.spend')
	}

	// [HOTEL_USER]: 'Revenue',
	// [EVENT_PLANNER_USER]: 'Spend',
};

const CANCEL_ROOM_EVENT_BLOCK: context = {
	[HOTEL_USER]: 'Room Block',
	[EVENT_PLANNER_USER]: 'Event Block',
};

const API_DOMAIN: { [key: string]: string } = {
	app: 'planner',
	hotel: 'hotel',
	www: 'portal'
}

@Service()
export class ContextService {
	private documentService = Container.get(DocumentService);

	public get user(): string {
		switch (this.documentService.getFirstDomain()) {
			case 'app':
				return EVENT_PLANNER_USER;
			case 'hotel':
				return HOTEL_USER;
			default:
				return GUEST_USER;
		}
	}

	get apiDomain(): string {
		return API_DOMAIN[this.documentService.getFirstDomain()] || 'portal';
	}

	public get isHotel(): boolean {
		return this.user === HOTEL_USER;
	}

	public get isPlanner(): boolean {
		return this.user === EVENT_PLANNER_USER;
	}

	public get isGuest(): boolean {
		return this.user === GUEST_USER;
	}

	public get cutOffDateText(): string {
		return CUT_OFF_DATE[this.user];
	}

	public get daysUntilEventText(): string {
		return START_DATE[this.user];
	}

	public get eventAttachmentText(): string {
		return EVENT_ATTACHMENT[this.user];
	}

	public get hotelAttachmentText(): string {
		return HOTEL_ATTACHMENT[this.user];
	}

	public get blockEventText(): string {
		return BLOCK_EVENT[this.user];
	}

	public get spendRevenueText(): string {
		return SPEND_REVENUE[this.user];
	}

	public get roomEventBlockText(): string {
		return CANCEL_ROOM_EVENT_BLOCK[this.user];
	}
}
