import { Service } from 'typedi';
const store = require('store');

@Service()
export class LocalStorageService {

    public set(name: string, value: any): void {
        store.set(name, value)
    }

    public get(name: string): any {
        const value = store.get(name);
        if (value === undefined || value === null || value === NaN) return '';
        
        return value;
    }

    public remove(name: string): void {
        store.remove(name);
    }

    public removeAll(): void {
        store.clearAll();
    }
}
