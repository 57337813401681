import { values, get, merge } from 'lodash';
import { Service } from 'typedi';

enum SupportedLanguages {
    ENGLISH = 'en',
    SPANISH = 'es',
    FRENCH = 'fr',
    GERMAN = 'de',
    ITALIAN = 'it',
    JAPANESE = 'ja',
    KOREAN = 'ko',
    DUTCH = 'nl',
    PORTUGUESE = 'pt',
    RUSSIAN = 'ru',
    THAI = 'th',
    CHINESE = 'zh',
}

const SUPPORTED_LANGUAGES: ReadonlyArray<SupportedLanguages> = values(
    SupportedLanguages
);

const DEFAULT_LANGUAGE: SupportedLanguages = SupportedLanguages.ENGLISH;

export interface Translator {
    (key: string, opts?: object): string;
}
export interface CurrencyFn {
    (value: any, code?: string, noDecimal?: boolean): string;
}
export interface NumberFn {
    (value: any): string;
}
export interface DateFn {
    (value: any, opts?: object): string | null;
}

export type Translation = {
    translator: Translator;
    userLanguage: string;
    numberFormatter: NumberFn;
    currencyFormatter: CurrencyFn;
    dateFormatter: DateFn;
};

@Service()
export class TranslationService {

    static t: Translator

    translator = (key: string, opts?: object) => TranslationService.t(key, opts)

    constructor(translator: Translator) {
        TranslationService.t = translator
    }
}

const parseTemplate = (
    templateString: string,
    templateOpts: object
): string => {
    return new Function('return `' + templateString + '`;').call(templateOpts);
};

const languageIsSupported = (language: string): boolean =>
    SUPPORTED_LANGUAGES.includes(<SupportedLanguages>language);

const getBaseLanguage = (language: string): string => language.split('-')[0];

const isADialect = (language: string): boolean => language.includes('-');

// Formatting OPTIONS - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
export const setDateFormatter = async (language: string): Promise<DateFn> => (
    value,
    options
) =>
    !value
        ? null
        : options
            ? new Intl.DateTimeFormat(language, options).format(value)
            : new Intl.DateTimeFormat(language).format(value);

export const setNumberFormatter = async (
    language: string
): Promise<NumberFn> => value => new Intl.NumberFormat(language).format(value);

export const unformatNumber = (str: string) => {
    let s = typeof str === 'number' ? (str as Number).toString() : str;
    return +s
        .split('')
        .filter(char => /\d/.test(char))
        .join('');
};

export const setCurrencyFormatter = async (
    language: string
): Promise<CurrencyFn> => (
    value: any,
    code: string | undefined | null,
    hideDecimal = false
) => {
        const currencyCode = code || 'USD';
        return new Intl.NumberFormat(
            language,
            hideDecimal
                ? {
                    style: 'currency',
                    currency: currencyCode,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }
                : {
                    style: 'currency',
                    currency: currencyCode,
                }
        ).format(value);
    };

const getTranslation = async (url: string, lng: string): Promise<object> => {
    let res = await fetch(`${url}/${lng}.json`);
    if (res.status !== 200) return Promise.resolve({})
    return await res.json()
}

const getCombinedTranslation = async (lng: string): Promise<object> => {

    let dict = {}, strapDict = {};

    try {
        [dict, strapDict] = await Promise.all([
            getTranslation(I18N_URL, lng),
            getTranslation(GROUPSTRAP_I18N_URL, lng)
        ])
    } catch (err) {
        console.log(err)
        return {}
    }

    return merge(strapDict, dict);

}

export const initTranslator = async (lng: string): Promise<Translator> => {

    let dict = {}, defaultDict = {}

    try {
        const apis = [
            getCombinedTranslation(DEFAULT_LANGUAGE)
        ]
        lng !== DEFAULT_LANGUAGE && apis.push(getCombinedTranslation(lng));

        [defaultDict = {}, dict = {}] = (await Promise.allSettled(apis)).map((result: any) => result?.value)        
    } catch (error) {
        console.error(error);
    }
    return (key: string, opts: object = {}): string => {
        const translation = get(dict, key, '') || get(defaultDict, key, '');
        if (!translation)
            console.error(
                `Could not return translation for key: ${key} from the ${lng} dictionary.`
            );

        return Object.keys(opts).length > 0
            ? parseTemplate(translation, opts)
            : translation;
    };
};

export const determineUserLanguage = (): SupportedLanguages => {
    const browserLanguage: string = navigator.language;
    const browserLanguages: ReadonlyArray<string> = navigator.languages;

    // uncomment next line to disable translations
    // return DEFAULT_LANGUAGE;

    // first, do we support the user's primary language, dialect or not
    if (languageIsSupported(browserLanguage))
        return <SupportedLanguages>browserLanguage;

    // so we don't support their primary language, is their primary a dialect?
    // if so, do we support the base language of the dialect
    const browserBaseLanguage = getBaseLanguage(browserLanguage);
    if (languageIsSupported(browserBaseLanguage))
        return <SupportedLanguages>browserBaseLanguage;

    // so we dont support their primary...dialect or not...
    // lets see if we support any of their other preferred languages
    // if a navigator language is a dialect, we check to see if we support that base language
    const supportedNonPrimaryLanguage: SupportedLanguages = <SupportedLanguages>(
        browserLanguages.find(
            language =>
                languageIsSupported(language) ||
                languageIsSupported(getBaseLanguage(language))
        )
    );

    // did we find a different language that is either a base language or dialect of a supported language?
    if (supportedNonPrimaryLanguage)
        return !isADialect(supportedNonPrimaryLanguage)
            ? <SupportedLanguages>supportedNonPrimaryLanguage
            : <SupportedLanguages>getBaseLanguage(supportedNonPrimaryLanguage);

    // so we don't support their primary and we don't support the base of the dialect
    // and we dont support any of their preferred languages...
    // lets return the default
    return DEFAULT_LANGUAGE;
};

export const getTestTranslator = () => {
    const language = 'en-US';

    const tf = (key: string, opts = {}, dict = {}): string => {
        const translation = get(dict, key, '');
        if (!translation)
            console.error(
                `Could not return translation for key: ${key} from the ${language} dictionary.`
            );

        return Object.keys(opts).length > 0
            ? parseTemplate(translation, opts)
            : translation;
    };

    const df: DateFn = (value, options) =>
        !value
            ? null
            : options
                ? new Intl.DateTimeFormat(language, options).format(value)
                : new Intl.DateTimeFormat(language).format(value);

    const nf: NumberFn = value => new Intl.NumberFormat(language).format(value);

    const cf: CurrencyFn = (
        value: any,
        code: string | undefined | null,
        hideDecimal = false
    ) => {
        const currencyCode = code || 'USD';
        return new Intl.NumberFormat(
            language,
            hideDecimal
                ? {
                    style: 'currency',
                    currency: currencyCode,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }
                : {
                    style: 'currency',
                    currency: currencyCode,
                }
        ).format(value);
    };

    let trans = {
        translator: tf,
        userLanguage: language,
        numberFormatter: nf,
        currencyFormatter: cf,
        dateFormatter: df,
    };
    return trans;
};
