import { css } from 'styled-components';

export const themeColor = {
    idemBlue: '#0095ff',
    idemWebsiteBg: '#f6f7f8',
    bodyBlack: '#4a4a4a',
    brownGray: '#a2a2a2',
    idemGreen: '#0cba80',
    idemYellow: '#f6ab2f',
    idemDashboardBg: '#fafafa',
    idemLightGreen: '#cef1e6',
    gray: '#9b9b9b',
    idemRed: '#fc665c',
    darkGray: '#777',
    green34aa44: '#34aa44',
    lightGray: '#ececec',
    idemBlack: '#333',
    idemWebsiteLine: '#e0e0e0',

    // new GRW design 2.0
    idemNewGold: '#9d8e58',
    idemNewHoverGold: '#776937',
    idemNewBlack: '#1c1c1c',
    idemNewGrey: '#6e6e6e',
    idemNewLightGrey: '#dcdcdc',
    idemNewLightGold: '#e6e3d5',

    // --- Groups360 colors ---
    // brand core
    brandBlue: '#02354A',
    brandBlueHover: '#022E40',
    brandBlueDisabled: '#B3C2C9',
    brandAccent: '#FF6C0C',
    brandAccentHover: '#DE5719',
    brandAccentDisabled: '#FFD3B6',
    brandSecondary: 'F2F2F0',

    // interface
    brandRed: '#B00020',
    brandRedHover: '#94041E',
    brandRedDisabled: '#E7B3BC',
    brandYellow: '#FED720',
    brandYellowHover: '#149332',
    brandYellowDisabled: '#FFF3BC',
    brandGreen: '#1EAE40',
    brandGreenHover: '#149332',
    brandGreenDisabled: '#BBE7C6',

    // brand basic
    brandWhite: '#FFFFFF',
    brandGray: '#F2F2F0',
    brandBlack: '#262523',
    brandBlackMedium: '#7D7C7B',
    brandBlackLight: '#BEBEBD',
    brandBlackHover: '#181818',
    brandBlackDisabled: '#E5E5E5',

    // body copy
    fontBlack: '#212121',
    textHyperlink: '#02354A',

    brandBoxShadow: '1px 0px 10px rgba(0, 0, 0, 0.1)'
}

export const themeBorder = {
    border1: '1px solid #eaedf3',
    border2: '1px solid #a2a2a2'
}

export const lightenColor = (color: string, percent: number): string => {
    const num = parseInt(color.replace('#', ''), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const B = (num >> 8 & 0x00FF) + amt;
    const G = (num & 0x0000FF) + amt;

    return "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
}

export const getRandomColor = (): string => {
    const letters = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const overlayCss = css`
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`