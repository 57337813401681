import Container, { Service } from 'typedi';
import { action, observable, computed } from 'mobx';
import queryString from 'query-string';

import { Hotel, IHotelJSON, TPartnerHotel } from 'models/hotel.model';
import { PREFIX, APIService, ICommonResponse } from './api.service';
import { ISelectOption } from 'components/react-select/custom-select/custom-select';
import { SessionStorageService } from './session-storage.service';
import { IMediaJSON } from 'models/media.model';
import { HotelPkgApi } from 'api/hotel-pkg.api';

const apiService = Container.get(APIService);
const sessionStorageService = Container.get(SessionStorageService);

interface ISearchHotelResponse {
	code: string,
	data: {
		results: Hotel[],
	},
	status: string,
}

interface IPartnerHotelsCountResponse extends ICommonResponse {
	data: {
		count: number;
	}
}

export const SELECT_ADD_NEW_ACMD = 'SELECT_ADD_NEW_ACMD';
export const SELECT_ASYNC_ADD_NEW_HOTEL = 'SELECT_ASYNC_ADD_NEW_HOTEL';

interface IHotelResponse extends ICommonResponse {
	data: IHotelJSON;
}

export interface IQueryRoomsAvailability {
	search?: string,
	limit: number,
	page: number,
	order?: 'asc' | 'dsc',
}

interface IPropertyData {
	property_provider: string;
	property_code: string;
	chain_code: string;
}

export type THotelCategory = {
	id: string;
	name: string;
	description: string;
	image_gallery: IMediaJSON[];
	in_use: boolean;
	partial_redemption: boolean;
	slug?: string;
}

@Service()
export class HotelService {
	@observable currentHotel?: Hotel;

	categories: THotelCategory[] = [];
	setCategories = (cs: THotelCategory[]) => {
		this.categories = cs;
	}
	pushCategory = (category: THotelCategory) => {
		this.categories.push(category);
	}
	deleteCategory = (categoryId: string) => {
		this.categories = this.categories.filter(c => c.id !== categoryId);
	}
	updateCategory = (category: THotelCategory) => {
		this.categories = this.categories.map(c => {
			if (c.id === category.id) {
				return category;
			} else {
				return c;
			}
		})
	}

	@action setCurrentHotel = (h: Hotel) => {
		this.currentHotel = h;
		sessionStorageService.set('hotelId', h.id);
		this.setCategories(h.categories);
	}

	setCurrency = (currency: string) => {
		this.currentHotel && (this.currentHotel.reservation_setting.currency = currency)
	}

	@computed get hotelId(): string {
		return this.currentHotel ? this.currentHotel.id : '';
	}

	@computed get hotelName(): string {
		return this.currentHotel ? this.currentHotel.name : '';
	}

	@computed get hotelLogoUrl(): string {
		return this.currentHotel ? this.currentHotel.logo_url : '';
	}

	@computed get currency(): string {
		return this.currentHotel?.reservation_setting.currency ?? 'SGD';
	}

	async getHotelById(id: string): Promise<Hotel | null> {
		const req = await apiService.get(`${PREFIX.HOTELS}${id}`, { 'Idem-Referer-Id': id });
		return await apiService.parseResponseData(
			req,
			(res: IHotelResponse) => new Hotel(id).fromJSON(res.data),
			null
		);
	}

	get pmsSupported(): boolean {
		return this.currentHotel ? this.currentHotel.is_pms : false;
	}

	get pmsProvider(): string {
		return this.currentHotel ? this.currentHotel.property_provider : '';
	}

	get pmsProviderCode(): string {
		return this.currentHotel ? this.currentHotel.property_code : '';
	}

	get partnerHotel(): TPartnerHotel[] {
		return this.currentHotel ? this.currentHotel.partners : [];
	}

	async searchHotel(q: string): Promise<ISelectOption[]> {
		if (q.length < 3) {
			return [];
		}

		const res = await apiService.get(`${PREFIX.HOTELS}search?q=${q}`);

		const handler = (json: ISearchHotelResponse): ISelectOption[] => {
			const { results } = json.data;
			const convertResults: any = results.map((item) => {
				return {
					value: item.id,
					label: item.name,
					provider: item.provider,
					accommodations: item.accommodations,
					description: item.description
				}
			})

			convertResults.push({
				value: SELECT_ASYNC_ADD_NEW_HOTEL,
				label: 'Add New Hotel',
				styleForAddNew: true,
			});

			return convertResults;
		}

		return apiService.parseResponseData(res, handler, []);
	}

	async connectPMSProperty(data: IPropertyData): Promise<boolean> {
		const res = await apiService.put(`${PREFIX.HOTELS}${this.hotelId}/pms`, JSON.stringify(data));
		return apiService.handleErrorBoolean(res);
	}

	async getPartnerHotels(getAll?: boolean): Promise<Hotel[]> {
		const res = await apiService.get(`${PREFIX.HOTELS}${this.hotelId}/partners`);

		const handler = (json: ISearchHotelResponse): Hotel[] => {
			const { results } = json.data;

			return getAll ? results : results.filter(r => r.status === 'active');
		}

		return apiService.parseResponseData(res, handler, []);
	}

	async getPartnerHotelsCount(): Promise<number> {
		const res = await apiService.get(`${PREFIX.HOTELS}${this.hotelId}/partners/count`);

		const handler = (json: IPartnerHotelsCountResponse): number => json.data.count

		return apiService.parseResponseData(res, handler, 0);
	}

	async setHotelDefault(id: string): Promise<boolean> {
		const res = await apiService.put(`${PREFIX.HOTELS}${id}/default`);
		return await apiService.handleErrorBoolean(res);
	}

	async sendMarketingOptinReport(emails: string[], type: string, query: HotelPkgApi.IOrdersListQuery): Promise<string[]> {
		const { start, end } = query;
		const q = queryString.stringify({ start, end });
		const exportUrl = `${PREFIX.HOTELS}${this.hotelId}/exports/marketing?${q}&type=${type}&tos_agree=1`
		const res = await apiService.put(exportUrl, JSON.stringify({ emails }))
		return apiService.parseResponseData(res, json => json.data.emails, [], {
			showSuccessMgs: true
		})
	}
}
