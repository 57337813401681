import React from 'react';
import cls from 'classnames';

import styles from './button-icon.scss';

import { ButtonNew, IButtonNewProps } from '../button-new';

export interface IButtonIconProps extends IButtonNewProps {
	icon: React.ReactNode;
	iconRight?: boolean;
	iconSize?: 12 | 16 | 18 | 22 | 24;
	alignCenter?: boolean;
}

export class ButtonIcon extends React.PureComponent<IButtonIconProps> {
	render() {
		const { icon, iconRight, iconSize, alignCenter, ...rest } = this.props;
		const iconRender =
			<div style={{ height: `${iconSize ? iconSize : 14}px`, width: `${iconSize ? iconSize : 14}px` }}
				className={`${styles.icon} ${this.props.disabled ? styles.disabled : ''}`}>
				{icon}
			</div>;

		const className = cls(
			styles.wrapper,
			{ [styles.alignCenter]: alignCenter }
		)

		return (
			<ButtonNew {...rest}>
				<div className={className}>
					{iconRight ?
						<React.Fragment>
							{this.props.children && <div>{this.props.children}&nbsp;&nbsp;</div>}
							{iconRender}
						</React.Fragment>
						:
						<React.Fragment>
							{iconRender}
							{this.props.children && <div>&nbsp;&nbsp;{this.props.children}</div>}
						</React.Fragment>
					}
				</div>
			</ButtonNew>
		);
	}
}
