import { Container, Service } from 'typedi';
import React from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import { action, computed, observable, when } from 'mobx';

import { Modal, ModalModel } from 'components/modal/modal';
import { DialogNotification } from 'dialogs/dialog-notification/dialog-notification';
import { BUTTON_COLOR } from 'ui/button/button-new';
import { TranslationService } from './translation.service';

export interface IDialogNotify {
	header: string;
	body: React.ReactNode;
	btnText?: string;
	btnColor?: BUTTON_COLOR;
	// translator: Translator;
}

export interface IDialogConfirm {
	header: string;
	body: React.ReactNode;
	btnText: string;
	btnColor?: BUTTON_COLOR;
	btnCancelText?: string;
	btnHeight?: 30 | 37 | 50;
	// translator: Translator;
}

export interface IDialogMultiAction {
	header: string,
	body: React.ReactNode,
	actionBtns: Array<{
		text: string;
		resolveValue: string;
		color?: BUTTON_COLOR;
		icon?: React.ReactNode;
	}>;
	// translator: Translator;
}

const translation = {
	get translator() {return Container.get(TranslationService).translator},
}


export const MASTER_BOOKING: IDialogConfirm = {
	get header() {return translation.translator('Dialog.CreateMasterBookingLink.label')},
	get body() {return <p>{translation.translator('Dialog.CreateMasterBookingLink.desc')}</p>},
	get btnText() {return translation.translator('Dialog.CreateMasterBookingLink.btnText')}
}

export const DELETE_ASSIGN_HOTEL: IDialogConfirm = {
	// get translator() {return Container.get(TranslationService).translator},
	get header() {return translation.translator('Dialog.RemoveHotel.label')},
	get body() {return <div dangerouslySetInnerHTML={{
		__html: translation.translator('Dialog.RemoveHotel.desc')
	}} />},
	get btnText() {return translation.translator('Dialog.RemoveHotel.btnText')},
	btnColor: BUTTON_COLOR.RED
}

export const RATE_CODE_DIALOG: IDialogConfirm = {
	// get translator() {return Container.get(TranslationService).translator},
	get header() {return translation.translator('Dialog.RateCodeFound.label')},
	get body() {return <p>{translation.translator('Dialog.RateCodeFound.desc')}</p>},
	get btnText() {return translation.translator('Dialog.RateCodeFound.btnText')}
}

export const ROOM_BLOCK_CODE_DIALOG: IDialogConfirm = {
	// get translator() {return Container.get(TranslationService).translator},
	get header() {return translation.translator('Dialog.RoomBlockCodeFound.label')},
	get body() {return <p>{translation.translator('Dialog.RoomBlockCodeFound.desc')}</p>},
	get btnText() {return translation.translator('Dialog.RoomBlockCodeFound.btnText')}
}

export const NON_INTEGRATED_BLOCK_CODE_DIALOG: IDialogConfirm = {
	// get translator() {return Container.get(TranslationService).translator},
	get header() {return translation.translator('Dialog.NonIntegratedBlockCode.label')},
	get body() {return <div dangerouslySetInnerHTML={{
		__html: translation.translator('Dialog.NonIntegratedBlockCode.desc')
	}} />},
	get btnText() {return translation.translator('Dialog.NonIntegratedBlockCode.btnText')}
}

export const COPY_BLOCK_IMAGES_DIALOG: IDialogConfirm = {
	// get translator() {return Container.get(TranslationService).translator},
	get header() {return translation.translator('Dialog.CopyMasterReservationSiteImages.label')},
	get body() {return <div dangerouslySetInnerHTML={{
		__html: translation.translator('Dialog.CopyMasterReservationSiteImages.desc')
	}} />},
	get btnText() {return translation.translator('Dialog.CopyMasterReservationSiteImages.btnText')}
}

@observer
class NotificationApp extends React.Component {
	private modalService = Container.get(ModalService);

	render(): React.ReactNode {
		return this.modalService.notificationContentList;
	}
}

@observer
class DialogApp extends React.Component {
	private modalService = Container.get(ModalService);

	render(): React.ReactNode {
		const length = this.modalService.dialogContentList.length;
		if (length === 0) {
			return null;
		}
		return this.modalService.dialogContentList;
	}
}

@Service()
export class ModalService {
	public dialogs = observable.map<ModalModel, React.ReactNode>();

	@computed get notificationContentList(): React.ReactNode[] {
		return Array.from(this._notificationMap.toJS().values());
	}

	@computed get dialogContentList(): React.ReactNode[] {
		const list = Array.from(this.dialogs.toJS().values());
		if (list.length > 0) {
			document.body.classList.add('modal-open');
		} else {
			document.body.classList.remove('modal-open');
		}

		return list;
	}

	@action.bound
	async openDialog(content: React.ReactNode, optionalModalModel?: ModalModel<any>): Promise<ModalModel> {
		const model = optionalModalModel || new ModalModel();

		if (this._notificationMap.has(model)) {
			return model;
		}

		when(() => model.isClosed, () => {
			this.dialogs.delete(model);
		});

		this.dialogs.set(model, (
			<Modal key={model.id} model={model}>
				{content}
			</Modal>
		));

		return model;
	}

	@action.bound
	async notify(content: React.ReactNode, optionalModalModel?: ModalModel): Promise<ModalModel> {
		const model = optionalModalModel || new ModalModel();
		model.hideOverlay = true;
		model.closeWhenClickOutside = false;
		if (this._notificationMap.has(model)) {
			return model;
		}
		when(() => model.isClosed, () => {
			this._notificationMap.delete(model);
		});
		this._notificationMap.set(model, (
			<Modal key={model.id} model={model}>
				{content}
			</Modal>
		));
		return model;
	}

	openDialogNotify = async (props: IDialogNotify): Promise<void> => {
		const DialogNotify = require('dialogs/dialog-confirm/dialog-notify').DialogNotify
		const modalModel = new ModalModel();
		await this.openDialog(
			<DialogNotify
				modalModel={modalModel}
				{...props}
			/>,
			modalModel
		)
	}

	openDialogConfirm = async (props: IDialogConfirm): Promise<boolean> => {
		const DialogConfirm = require('dialogs/dialog-confirm/dialog-confirm').DialogConfirm;
		const modalModel = new ModalModel<boolean>();
		this.openDialog(
			<DialogConfirm
				modalModel={modalModel}
				{...props}
			/>,
			modalModel
		);
		await when(() => modalModel.resolvedValue !== undefined || modalModel.isClosed);
		return !!modalModel.resolvedValue;
	}

	openDialogConfirmNew = async (props: IDialogConfirm): Promise<boolean> => {
		const DialogConfirmNew = require('new-dialogs/dialog-confirm-new/dialog-confirm-new').DialogConfirmNew;
		const modalModel = new ModalModel<boolean>();
		this.openDialog(
			<DialogConfirmNew
				modalModel={modalModel}
				{...props}
			/>,
			modalModel
		);
		await when(() => modalModel.resolvedValue !== undefined || modalModel.isClosed);
		return !!modalModel.resolvedValue;
	}

	openDialogMultiAction = async (props: IDialogMultiAction): Promise<string | undefined> => {
		const DialogMultiAction = require('dialogs/dialog-confirm/dialog-multi-action').DialogMultiAction;
		const modalModel = new ModalModel<string>();
		this.openDialog(
			<DialogMultiAction
				modalModel={modalModel}
				{...props}
			/>,
			modalModel
		);
		await when(() => modalModel.resolvedValue !== undefined || modalModel.isClosed);
		return modalModel.resolvedValue;
	}

	showNotification(message: string): void {
		const modal = new ModalModel();
		this.notify(
			<DialogNotification modalModel={modal} body={message} />,
			modal,
		);
	}

	exportFileWarning = async (): Promise<boolean> => {
		const EXPORT_FILE: IDialogConfirm = {
			header: translation.translator('Dialog.ExportFileWarning.label'),
			body: <p dangerouslySetInnerHTML={{
				__html: translation.translator('Dialog.ExportFileWarning.desc')
			}} />,
			btnText: translation.translator('Dialog.ExportFileWarning.btnText'),
		}

		return this.openDialogConfirm(EXPORT_FILE);
	}

	private _notificationMap = observable.map<ModalModel, React.ReactNode>();
}

((): void => {
	const el = document.createElement("div");
	const body = document.querySelector("body")!;
	el.style.cssText = 'position: fixed; bottom: 50px; left: 50%; transform: translateX(-50%); z-index: 10001;';
	body.appendChild(el);

	ReactDOM.render(
		<NotificationApp />,
		el,
	);
})();

((): void => {
	const el = document.createElement("div");
	const body = document.querySelector("body")!;
	body.appendChild(el);

	ReactDOM.render(
		<DialogApp />,
		el,
	);
})();
