export const clearWorthlessField = (data: any, excludedFields?: string[]): void => {
    Object.keys(data).forEach((key: any) => {
        if (!excludedFields?.includes(key) && (data[key] === undefined || data[key] === '' || data[key] === null)) {
            delete data[key];
        }
    });
}


export const checkDirtyObject = (target: any = {}, source: any = {}): boolean => {
    if (typeof target !== 'object' || typeof source !== 'object' || target === null || source === null) {
        return true;
    }

    return Object.keys(target).some((key) => {
        if (typeof target[key] === 'object') {
            return checkDirtyObject(target[key], source[key]);
        }
        if (typeof target[key] === 'string' || typeof target[key] === 'number') {
            return target[key] !== source[key];
        }
        return true;
    });
}

export const flattenObject = (ob: any): any => {
    let toReturn: any = {};

    for (let i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) == 'object') {
            const flatObject: any = flattenObject(ob[i]);
            for (let x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                toReturn[i + '.' + x] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
};

export const compareObjectSafe = (object1: any, object2: any): boolean => {
    const obj1JSON = JSON.stringify(object1, Object.keys(flattenObject(object1)).sort());
    const obj2JSON = JSON.stringify(object2, Object.keys(flattenObject(object2)).sort());

    return obj1JSON === obj2JSON;
};

export const isJSONString = (value: string): boolean => {
    try {
        JSON.parse(value);
    } catch (e) {
        return false;
    }
    return true;
}