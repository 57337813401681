import React from 'react';
import cls from 'classnames';
import styles from './dialog.scss';
import { ButtonIcon } from 'ui/button/button-icon/button-icon';
import { SVGIcon } from 'assets/svg';

type TProps = {
	children?: React.ReactNode;
	className?: string;
	onCloseDialog?: () => void;
	id?: string;
}

export function Dialog(props: TProps): React.ReactElement<TProps> {
	const className = cls({
		[styles.dialog]: true,
	}, props.className);

	return (
		<div className={className}>
			{props.children}
		</div>
	)
}

export function DialogHeader(props: TProps): React.ReactElement<TProps> {
	const className = cls({
		[styles.header]: true,
	}, props.className);
	return (
		<header className={className}>
			<div>
				{props.children}
			</div>
			<ButtonIcon
				noBackground
				noPadding
				onClick={props.onCloseDialog}
				icon={<SVGIcon.Cross fill="#000" />}
				iconSize={16}
			/>
		</header>
	)
}

export function DialogBody(props: TProps): React.ReactElement<TProps> {
	const className = cls({
		// [styles.body]: true,
	}, props.className);
	return (
		<section id={props.id} className={className}>
			{props.children}
		</section>
	)
}

export function DialogFooter(props: TProps): React.ReactElement<TProps> {
	const className = cls({
		[styles.footer]: true,
	}, props.className);
	return (
		<footer className={className}>
			{props.children}
		</footer>
	)
}
