import { TABLE_SORT_TYPE } from 'constants/table';

export function copyFields<T>(target: T, source: Readonly<Partial<T>>): T {
	for (const id in source) {
		if (source.hasOwnProperty(id) && source[id] !== null) {
			target[id] = source[id] as any;
		}
	}
	return target;
}

interface IStyles {
	[key: string]: string,
}

export function mergeClassName(target: any, source: any) {
	if (!source) {
		return target;
	}
	const res = {} as IStyles;
	for (const id in target) {
		if (target.hasOwnProperty(id)) {
			res[id] = (target[id] || '') + ' ' + (source[id] || '');
		}
	}
	return res;
}

export interface IGroupByResult<T> {
	[key: string]: T[]
}

export function groupBy<T>(arrayInput: T[], key: keyof T): IGroupByResult<T> {
	return arrayInput.reduce((result: IGroupByResult<T>, t: T) => {
		if (!t[key]) {
			return result;
		}
		(result[t[key] as any] = result[t[key] as any] || []).push(t);
		return result;
	}, {});
}

export function notEmpty<T>(value: T | null | undefined): value is T {
	return value !== null && value !== undefined;
}

export type Comparer<T> = (a: T, b: T) => number

export function aZComparer(a: string, b: string): number {
	if (a > b) { return 1; }
	if (a < b) { return -1; }
	return 0;
}

export function zAComparer(a: string, b: string): number {
	return - aZComparer(a, b);
}

export function INComparer(a: number, b: number): number {
	return a - b;
}

export function NIComparer(a: number, b: number): number {
	return b - a;
}

export const COMPARER_TO_SORT_TYPE_MAP = new Map<Comparer<any>, TABLE_SORT_TYPE>(
	[
		[aZComparer, 'asc'],
		[zAComparer, 'desc'],
		[INComparer, 'asc'],
		[NIComparer, 'desc'],
	]
);

export function* packGen<T>(t: T[]): IterableIterator<T> {
	while (true) {
		yield* t;
	}
}

export function cloneObject<T>(obj: T): T {
	return JSON.parse(JSON.stringify(obj));
}