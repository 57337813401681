import React from 'react';
import classNames from 'classnames';
import styles from './button-outline.scss';
import { Link } from 'react-router-dom';
import { st } from 'constants/style-names-global';

export enum BUTTON_OUTLINE_COLOR {
	RED = 'RED',
	GRAY = 'GRAY',
	GREEN = 'GREEN',
	ORANGE = 'ORANGE',
	BLACK = 'BLACK',
	GOLD = 'GOLD',
	CLASSIC = 'CLASSIC',
};

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface IButtonOutlineProps extends ButtonProps {
	className?: string;
	color?: BUTTON_OUTLINE_COLOR;
	to?: string;
	href?: string;
	target?: string;
	smallSize?: boolean;
	smallOutLine?: boolean;
	customHeight?: 30 | 37 | 50 | 80;
	fullWidth?: boolean;
}

export class ButtonOutline extends React.PureComponent<IButtonOutlineProps> {
	render() {
		const { className, color, to, href, disabled, target, fullWidth, smallSize, smallOutLine, customHeight, style, ...rest } = this.props;

		const classCombined = classNames({
			[styles.buttonOutline]: true,
			[st.w100]: fullWidth,
			[styles.smallSize]: smallSize,
			[styles.buttonOutlineRed]: color === BUTTON_OUTLINE_COLOR.RED,
			[styles.buttonOutlineGray]: color === BUTTON_OUTLINE_COLOR.GRAY,
			[styles.buttonOutlineGreen]: color === BUTTON_OUTLINE_COLOR.GREEN,
			[styles.buttonOutlineBlack]: color === BUTTON_OUTLINE_COLOR.BLACK,
			[styles.buttonOutlineClassic]: color === BUTTON_OUTLINE_COLOR.CLASSIC,
			[styles.buttonOutlineGold]: color === BUTTON_OUTLINE_COLOR.GOLD,
			[styles.buttonOutlineOrange]: color === BUTTON_OUTLINE_COLOR.ORANGE,
			[styles.smallOutLine]: smallOutLine
		}, className);

		const styleObject = {
			...style,
			...customHeight ? { height: `${customHeight}px` } : {},
		};

		if (disabled) {
			return (
				<span style={{ cursor: 'not-allowed' }}>
					<button className={classCombined} disabled={true} {...rest as ButtonProps} style={{ ...styleObject, pointerEvents: 'none' }}>
						{this.props.children}
					</button>
				</span>
			)
		}

		if (to) {
			return (
				<Link to={to}>
					<button {...rest as ButtonProps} className={classCombined} style={styleObject}>
						{this.props.children}
					</button>
				</Link>
			);
		}

		if (href) {
			return (
				<a className={classCombined} href={href} target={target} style={{ ...styleObject, textDecoration: 'none' }}>{this.props.children}</a>
			);
		}

		return <button {...rest as ButtonProps} className={classCombined} style={styleObject}>{this.props.children}</button>;
	}
}
