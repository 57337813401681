import ReactGA, { Tracker } from "react-ga";
import ReactGA4 from "react-ga4";
import Container, { Service } from "typedi";

import { DocumentService } from "./document.service";
import { TIdName } from "models/common.model";
import { HistoryService } from "./history.service";

const documentService = Container.get(DocumentService);
const historyService = Container.get(HistoryService);

type TCommonFieldObject = {
	brand?: string;
	category?: string;
	variant?: string;
	position?: number;
} & TIdName;

export type TImpressionFieldObject = {
	list?: string;
} & TCommonFieldObject;

export type TProductFieldObject = {
	price: number;
	quantity: number;
	coupon?: string;
} & TCommonFieldObject;

export type TPromoFieldObject = {
	creative?: string;
	position?: string;
} & TIdName;

export type TActionFieldObject = {
	id: string;
	affiliation: string;
	revenue: number;
	tax: number;
	step?: number;
	shipping?: number;
	coupon?: string;
	list?: string;
	option?: string;
	currency: string;
};

// type TEcommerceAction = 'click' | 'detail' | 'add' | 'remove' | 'checkout' | 'checkout_option' | 'purchase' | 'refund' | 'promo_click';

const excludedLinks = "/products/";

@Service()
export class GaService {
	private isGaInitialized = false;
	private isGa4Initialized = false;
	private trackerNames: string[] = [];

	loadGoogleTag = (uaId?: string, ga4Id?: string) => {
		!this.isGaInitialized && this.loadGa(uaId);
		!this.isGa4Initialized && this.loadGa4(ga4Id);
	};

	loadGa4(additionalId?: string) {
		try {
			let metaTagName = "google_tag_ga4";
			switch (historyService.routeType) {
				case "voucher":
				case "redemption":
					metaTagName = "google_tag_ga4_gifting";
					break;
				case "reservation":
					metaTagName = "google_tag_ga4_grw";
					break;
			}

			const trackers = this.getTrackers(additionalId, metaTagName);
			if (trackers.length > 0) {
				ReactGA4.initialize(trackers);
				this.isGa4Initialized = true;
			}
		} catch (e) {
			console.log(e);
		}
	}

	loadGa = (additionalId?: string) => {
		const trackers = this.getTrackers(additionalId, "google_tag");

		if (trackers.length > 0) {
			this.trackerNames = trackers.map((t) => t.gaOptions!.name!);

			ReactGA.initialize(trackers);

			const ga = ReactGA.ga();

			this.trackerNames.forEach((name) => ga(`${name}.require`, "ec"));

			this.isGaInitialized = true;
		}
	};

	getTrackers = (additionalId?: string, metaTagName?: string): Tracker[] => {
		const trackers: Tracker[] = [];
		const idemTagId = metaTagName
			? documentService.getMetaContent(metaTagName)
			: undefined;

		if (idemTagId) {
			trackers.push({
				trackingId: idemTagId,
				gaOptions: {
					name: "idemTracker",
				},
			});
		}

		if (additionalId) {
			trackers.push({
				trackingId: additionalId,
				gaOptions: {
					name: "hotelTracker",
				},
			});
		}

		return trackers;
	};

	trackPageView = () => {
		this.isGaInitialized &&
			!location.pathname.includes(excludedLinks) &&
			ReactGA.pageview(location.pathname, this.trackerNames);
	};

	trackImpressionProducts = (products: TImpressionFieldObject[]) => {
		const ga = ReactGA.ga();

		this.trackerNames.forEach((name) => {
			products.forEach((p) => {
				ga(`${name}.ec:addImpression`, p);
				ga(`${name}.send`, "pageview");
			});
		});

		products.forEach((p) => {
			gtag('event', 'view_item', {
				//currency: "USD", The account default should be picked up
				items: [
					{
						item_id: p.id,
						item_name: p.name,
						index: p.position,
						item_brand: p.brand,
						item_category: p.category,
						item_variant: p.variant,
					},
				],
			});
		});
	}

	trackViewProduct = (product: TImpressionFieldObject) => {
		const ga = ReactGA.ga();

		this.trackerNames.forEach((name) => {
			ga(`${name}.ec:addProduct`, product);
			ga(`${name}.ec:setAction`, "detail");
		});
		
		ReactGA.pageview(location.pathname, this.trackerNames);

		gtag('event', 'select_item', {
			//currency: "USD",
			//value: 0, 
			items: [
				{
					item_id: product.id,
					item_name: product.name,
					index: product.position,
					item_brand: product.brand,
					item_category: product.category,
					item_variant: product.variant,
					quantity: 1
				}
			]
		});
	};

	trackAddProduct = (product: TProductFieldObject) => {
		const ga = ReactGA.ga();

		this.trackerNames.forEach((name) => {
			ga(`${name}.ec:addProduct`, product);
			ga(`${name}.ec:setAction`, "add");
			ga(`${name}.send`, "event", "UX", "click", "Add to Cart");
		});
		
		gtag('event', 'add_to_cart', {
			//currency: "USD",
			//value: product.price * product.quantity,
			items: [
				{
					item_id: product.id,
					item_name: product.name,
					coupon: product.coupon,
					index: product.position,
					item_brand: product.brand,
					item_category: product.category,
					item_variant: product.variant,
					quantity: product.quantity
				},
			],
		});
	};

	trackRemoveProduct = (product: TProductFieldObject) => {
		const ga = ReactGA.ga();

		this.trackerNames.forEach((name) => {
			ga(`${name}.ec:addProduct`, product);
			ga(`${name}.ec:setAction`, "remove");
			ga(`${name}.send`, "event", "UX", "click", "Remove from Cart");
		});

		gtag('event', 'remove_from_cart', {
			//currency: "USD",
			//value: product.price * product.quantity,
			items: [
				{
					item_id: product.id,
					item_name: product.name,
					coupon: product.coupon,
					index: product.position,
					item_brand: product.brand,
					item_category: product.category,
					item_variant: product.variant,
					quantity: product.quantity
				},
			],
		});
	};

	trackCheckoutFlow = (products: TProductFieldObject[], step: number) => {
		const ga = ReactGA.ga();
		const productsArray: Object[] = [];
		//let sum: number = 0;

		products.forEach((p) => {
			productsArray.push({
				item_id: p.id,
				item_name: p.name,
				coupon: p.coupon,
				index: p.position,
				item_brand: p.brand,
				item_category: p.category,
				item_variant: p.variant,
				quantity: p.quantity
			})
			//sum += (p.price * p.quantity);
		});
		
		this.trackerNames.forEach((name) => {
			products.forEach((p) => {
				ga(`${name}.ec:addProduct`, p);
			});
			ga(`${name}.ec:setAction`, "checkout", { step });
			ga(`${name}.send`, "event", "Checkout", `Step ${step}`);
		});
		
		if (step === 1) {
			gtag('event', 'begin_checkout', {
				//currency: "USD",
				//value: sum,
				items: productsArray,
			});
		}
	};

	trackTransaction = (
		products: TProductFieldObject[],
		transaction: TActionFieldObject
	) => {
		const ga = ReactGA.ga();
		const productsArray: Object[] = [];
		this.trackerNames.forEach((name) => {
			products.forEach((p) => {
				ga(`${name}.set`, "currencyCode", transaction.currency);
				ga(`${name}.ec:addProduct`, p);
			});

			ga(`${name}.ec:setAction`, "purchase", transaction);
		});

		products.forEach((p) => {
			productsArray.push({
				item_id: p.id,
				item_name: p.name,
				coupon: p.coupon,
				index: p.position,
				item_brand: p.brand,
				item_category: p.category,
				item_variant: p.variant,
				quantity: p.quantity
				//price: p.price Help Jeressia!
			})
		});

		gtag('event', 'add_shipping_info', {
			currency: transaction.currency,
			value: transaction.revenue,
			items: productsArray,
			coupon: transaction.coupon,
		});

		gtag('event', 'add_payment_info', {
			currency: transaction.currency,
			value: transaction.revenue,
			items: productsArray,
			coupon: transaction.coupon,
		});

		gtag('event', 'purchase', {
			currency: transaction.currency,
			value: transaction.revenue,
			transaction_id: transaction.id,
			coupon: transaction.coupon,
			shipping: transaction.shipping,
			tax: transaction.tax,				
			items: productsArray,
		});
	}
}
