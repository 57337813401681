import React from 'react';
import ReactModal from 'react-modal';
import styles from './spinner.scss';
import { observer } from 'mobx-react';
import Container from 'typedi';
import { LoadingService } from 'services/loading.service';

const loadingService = Container.get(LoadingService)
@observer
export class Loading extends React.Component {
    render() {
        return (
            <ReactModal isOpen={loadingService.isLoading} style={{ overlay: { backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: '10001' }, content: { background: 'none', border: 'none' } }}>
                <div className={styles.spinnerWrapper}>
                    <div className={styles.spinnerElements}>
                        <div className={styles.spinner} />
                        <div className={styles.text}>{loadingService.loadingText}</div>
                    </div>
                </div>
            </ReactModal>
        )
    }
}

