import Container, { Service } from 'typedi';
import { create } from 'utils/most';
import { Stream, Subscription } from 'most';
import { copyFields } from 'utils/object-parse';
import { TranslationService } from './translation.service';

const enum META_NAMES {
	CSRF_TOKEN = 'csrf_token',
	TIME_ZONE = 'timezone',
}

export const SITE_TITLES: any = {
	get translator() { return Container.get(TranslationService).translator },
	get hotel() { return SITE_TITLES.translator('SiteTitle.hotel') },
	get app() { return SITE_TITLES.translator('SiteTitle.app') }
}

export const PAGE_TITLES = {
	get translator() { return Container.get(TranslationService).translator },
	get MANAGE_USERS() { return PAGE_TITLES.translator('PageTitle.manageUsers') },
	get CONFIRM_OTP() { return PAGE_TITLES.translator('PageTitle.confirmOtp') },
	get SET_PASSWORD() { return PAGE_TITLES.translator('PageTitle.setPassword') },
	get REGISTER_ACCOUNT() { return PAGE_TITLES.translator('PageTitle.registerAccount') },
	get BLOCK_OVERVIEW() { return PAGE_TITLES.translator('PageTitle.blockOverview') },
	get BLOCK_BOOKINGS() { return PAGE_TITLES.translator('PageTitle.blockBookings') },
	get BLOCK_EDIT() { return PAGE_TITLES.translator('PageTitle.blockEdit') },
	get BLOCK_NEW() { return PAGE_TITLES.translator('PageTitle.blockNew') },
	get EVENT_EDIT() { return PAGE_TITLES.translator('PageTitle.eventEdit') },
	get EVENT_NEW() { return PAGE_TITLES.translator('PageTitle.eventNew') },
	get MB_INVITE_LIST() { return PAGE_TITLES.translator('PageTitle.mbInviteList') },
	get EP_TEAM_LIST() { return PAGE_TITLES.translator('PageTitle.epTeamList') },
	get EP_TEAM_EDIT() { return PAGE_TITLES.translator('PageTitle.epTeamEdit') },
	get EP_TEAM_MANAGE() { return PAGE_TITLES.translator('PageTitle.epTeamManage') },
	get RESERVATION_SETUP() { return PAGE_TITLES.translator('PageTitle.reservationSetup') },
	get ALL_BLOCKS() { return PAGE_TITLES.translator('PageTitle.allBlocks') },
	get EDIT_PROFILE() { return PAGE_TITLES.translator('PageTitle.editProfile') },
	get HOTEL_SETTING_LIST_RATE_CODE() { return PAGE_TITLES.translator('PageTitle.hotelSettingListRateCode') },
	get HOTEL_SETTING_EDIT_RATE_CODE() { return PAGE_TITLES.translator('PageTitle.hotelSettingEditRateCode') },
	get PAYMENT_PORTAL() { return PAGE_TITLES.translator('PageTitle.paymentPortal') },
	get GRW_PREFIX() { return PAGE_TITLES.translator('PageTitle.grwPrefix') },
	get VENUES_LIST() { return PAGE_TITLES.translator('PageTitle.venuesList') },
	get VENUES_ADD() { return PAGE_TITLES.translator('PageTitle.venuesAdd') },
	get VENUES_EDIT() { return PAGE_TITLES.translator('PageTitle.venuesEdit') },
	get MY_EVENTS() { return PAGE_TITLES.translator('PageTitle.myEvents') },
	get VOUCHER_WEBSITE() { return PAGE_TITLES.translator('PageTitle.voucherWebsite') },
	get VOUCHER_ORDER() { return PAGE_TITLES.translator('PageTitle.voucherOrder') },
	get OUTLET_LIST() { return PAGE_TITLES.translator('PageTitle.outletList') },
	get OUTLET_ADD() { return PAGE_TITLES.translator('PageTitle.outletAdd') },
	get OUTLET_EDIT() { return PAGE_TITLES.translator('PageTitle.outletEdit') }
}

@Service()
export class DocumentService {
	public readonly clickStream: Stream<MouseEvent>;

	private documentClickStream = create<MouseEvent>("documentClickStream");

	private trackedSubscriber = new Map<any, Subscription<any>>();

	constructor() {
		this.clickStream = this.documentClickStream.multicast();
		document.addEventListener("click", e => {
			this.documentClickStream.emit(e);
		});
	}

	public onClickOutside(dom: React.RefObject<HTMLElement>, callback: (e: MouseEvent) => any): Subscription<any> {
		const res = this.clickStream.subscribe({
			next: (e: MouseEvent) => {

				if (!dom.current) {
					return;
				}

				if (e.target instanceof Node && !dom.current.contains(e.target)) {
					callback(e);
				}
			},
			complete(): void { },
			error(): void { },
		});
		this.trackedSubscriber.set(callback, res);
		return res;
	}

	public offClickOutside(callback: any): void {
		const sub = this.trackedSubscriber.get(callback);
		sub && sub.unsubscribe();
		this.trackedSubscriber.delete(callback);
	}

	public copyValueToClipboard(value: string): void {
		// 1: create an input and set attribute
		const clipBoard = document.createElement("input");
		clipBoard.setAttribute("type", "text");
		clipBoard.setAttribute("style", "height: 0; opacity: 0; position: absolute;");
		clipBoard.setAttribute("value", value);

		// 2: append to document
		document.body.appendChild(clipBoard);

		// 3: select input and copy
		clipBoard.select();
		document.execCommand("copy");

		// 4: remove input
		clipBoard.remove();
	}

	public getMetaContent(name: string): string {
		const element = document.querySelector(`meta[name=${name}]`);

		if (element) {
			return element.getAttribute("content") || '';
		}
		return '';
	}

	public setMetaContent(name: string, content: string): void {
		const element = document.querySelector(`meta[name=${name}]`);

		if (element) {
			element.setAttribute('content', content);
		} else {
			const headElement = document.getElementsByTagName('head');

			if (headElement) {
				const newElement = document.createElement('meta');
				newElement.setAttribute('name', name);
				newElement.setAttribute('content', content);

				headElement && headElement[0].appendChild(newElement);
			}
		}
	}

	public getCSRFToken(): string {
		return this.getMetaContent(META_NAMES.CSRF_TOKEN);
	}

	public setCSRFToken(content: string): void {
		this.setMetaContent(META_NAMES.CSRF_TOKEN, content);
	}

	public getTimeZone(): string {
		return this.getMetaContent(META_NAMES.TIME_ZONE);
	}

	public redirect(path: string): void {
		location.href = path;
	}

	public goBack(): void {
		window.history.back();
	}

	public reload(): void {
		location.reload();
	}

	public getSiteTitle(): string {
		return SITE_TITLES[this.getFirstDomain()];
	}

	public getFirstDomain(): string {
		return location.hostname.substr(0, location.hostname.indexOf("."))
	}

	public moveNextTabIndex(type: string, index: number): void {
		const nextElem: HTMLElement | null = document.querySelector(`input[type=${type}][tabindex='${index}']`);
		if (nextElem) {
			nextElem.focus();
		}
	}

	public getCurrentPath(): string {
		return location.pathname;
	}

	public getCurrentSearch(): string {
		return location.search;
	}

	public changeDocumentTitle(title: string, additional: string = ''): void {
		document.title = title + additional;
	}

	public setGRWPageTitle(title: string): void {
		document.title = title;
	}

	public getElementsBySelector(query: string): NodeListOf<HTMLInputElement | HTMLElement> {
		return document.querySelectorAll(query);
	}

	public triggerClickOutSide(): void {
		const element: HTMLElement = document.querySelector("#app") || new HTMLElement();
		element.click();
	}

	public setValueForInputElements(query: string, value: string): void {
		const elements = this.getElementsBySelector(query);
		elements.forEach(e => {
			if (e instanceof HTMLInputElement) {
				e.value = value;
			}
		});
	}

	public setCheckForCheckBoxes(query: string, checked: boolean): void {
		const elements = this.getElementsBySelector(query);
		elements.forEach(e => {
			if (e instanceof HTMLInputElement) {
				e.checked = checked;
			}
		});
	}

	public scrollToFirstError(): void {
		setTimeout(() => {
			this.scrollToElement('div[class*=errorField], input[class*="has-error"]');
		}, 250);
	}

	public scrollToElement(query: string) {
		const element = document.querySelector<HTMLElement>(query);

		if (element) {

			const y = element.getBoundingClientRect().top + window.scrollY - 100;
			window.scrollTo({
				top: y,
				behavior: 'smooth'
			})
		}
	}

	public scrollTop() {
		window.scrollTo({
			top: 0,
			behavior: 'auto'
		})
	}

	public getVariableValue(name: string): string {
		let result = '';
		const query = location.search.substring(1);

		query.split('&').some(v => {
			const pair = v.split('=');
			if (pair[0] === name) {
				result = pair[1];
				return true;
			}
			return false;
		});

		return result;
	}

	public addClassesToElement(query: string, classNames: string[]): void {
		const element = document.querySelector(query);

		if (element) {
			classNames.forEach(c => {
				element.classList.add(c);
			})
		}
	}

	public isBlockEditPage(): boolean {
		const reg = new RegExp(/^.*\/blocks\/[a-z0-9]+\/(edit|preview-message|preview-message-citywide)$/);
		return reg.test(this.getCurrentPath());
	}

	public isOAuthPage(): boolean {
		return this.getCurrentPath().includes('/pms/oauth-callback');
	}

	public getSlug(index: number): string {
		return this.getCurrentPath().split('/')[2 * index];
	}

	public getWebsiteSlug(): string {
		const domainWebsiteBasename = this.getMetaContent('website_basename');
		return this.getCurrentPath().replace(domainWebsiteBasename, '').split('/')[1];
	}

	public getSingleElementBySelector(query: string): HTMLElement | null {
		return document.querySelector(query);
	}

	createScriptTag(option: Partial<HTMLScriptElement>, callback?: any): HTMLScriptElement {
		const scriptTag = document.createElement('script');
		scriptTag.type = 'text/javascript';
		scriptTag.async = true;
		copyFields<HTMLScriptElement>(scriptTag, option);

		if (callback) {
			scriptTag.onload = callback;
		}

		return scriptTag;
	}

	public loadScript(option: Partial<HTMLScriptElement>, callback?: any): void {
		// 1. check exsiting
		const exsitingScriptTag = document.querySelector(`script[src='${option.src}']`);

		if (exsitingScriptTag) {
			if (callback) {
				callback();
			}
			return;
		}

		// 2. add script
		const scriptTag = this.createScriptTag(option, callback);

		document.getElementsByTagName('head')[0].appendChild(scriptTag);
	}

	public setGlobalVariable(key: string, value: any) {
		(window as any)[key] = value;
	}

	public setGlobalWebLinks() {
		this.setGlobalVariable('TOU_URL', process.env.TOU_URL);
		this.setGlobalVariable('WEBSITE_URL', process.env.WEBSITE_URL);
		this.setGlobalVariable('PRIVACY_URL', process.env.PRIVACY_URL);
		this.setGlobalVariable('HELP_CENTER', process.env.HELP_CENTER);
		this.setGlobalVariable('DOMAIN_HOTEL', process.env.DOMAIN_HOTEL);
		this.setGlobalVariable('GROUP_SYNC_GIFTING_URL', process.env.GROUP_SYNC_GIFTING_URL);
		this.setGlobalVariable('IS_PRODUCTION', process.env.ENV === 'Production');
		this.setGlobalVariable('I18N_URL', process.env.I18N_URL);
		this.setGlobalVariable('GROUPSTRAP_I18N_URL', process.env.GROUPSTRAP_I18N_URL);
	}

	public getHotelIdFromURL(): string {
		const currentPath = this.getCurrentPath();
		const domainHotelBasename = this.getMetaContent('hotel_basename');
		return currentPath.includes('/hotels/') ? currentPath.split('/')[domainHotelBasename ? 3 : 2] : '';
	}

	// public getHotelIdFromVariable(name: string): string {
	// 	// for APALEO
	// 	let value = this.getVariableValue(name) || '';

	// 	if (name === 'state') {
	// 		value = value.split('.')[1] || '';
	// 	}

	// 	return value;
	// }

	// TODO: check it
	public getHotelIdPrefix(): string {
		const hotelId = this.getHotelIdFromURL();
		const domainHotelBasename = this.getMetaContent('hotel_basename');
		return hotelId ? `${domainHotelBasename}/hotels/${hotelId}` : '';
	}

	applySameChildrenHeight(container: HTMLElement, query: string, rows: number, columns: number) {
		const elems = container.querySelectorAll(query) as NodeListOf<HTMLElement>;

		for (let i = 0; i < rows; i++) {
			const items: HTMLElement[] = [];
			const heights: number[] = [];

			for (let j = 0; j < columns; j++) {
				const item = elems[(i * columns) + j];
				if (item) {
					items.push(item);
					heights.push(item.offsetHeight);
				}
			}

			const maxHeight = Math.max(...heights);

			items.forEach(item => item.style.height = maxHeight + 'px');
		}
	}

	routeName = () => ({
		BLOCK: '/blocks',
		RESERVATION_VIEW: (resId: string) => `/reservations/${resId}/view`,
		RESERVATION_EDIT: (blockId: string, resId: string, plusString: string = '') => `/blocks/${blockId}/reservations/${resId}/edit${plusString}`,
		PAYMENT_PORTAL: '/payment-portal',
	})

	setFavicon = (url: string) => {
		if (!url) return;

		const elem = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
		elem!.setAttribute('href', url);
	}
}
