import React, { DOMAttributes, CSSProperties } from 'react';
import { SelfPosition, FlexDirectionProperty } from 'csstype';
import styled, { css } from 'styled-components';

interface IBoxInlineStyledProps {
	justify?: 'center' | 'space-between' | 'flex-end';
	alignItems?: SelfPosition | 'baseline' | 'stretch';
	isSameWidth?: boolean;
	spacer?: 0 | 4 | 8 | 14 | 20 | 30;
	direction?: FlexDirectionProperty;
}

const BoxInlineStyled = styled.div<IBoxInlineStyledProps>`
	display: flex;
	justify-content: ${({ justify }) => justify || 'flex-start'};
	align-items: ${({ alignItems }) => alignItems || 'center'};
	flex-direction: ${({ direction }) => direction || 'row'};

    & > *:not(:last-child) {
        margin-right: ${({ spacer }) => spacer !== undefined ? `${spacer}px` : '12px'};
    }

	${({ isSameWidth }) => isSameWidth && css`
		& > *{
			flex: 1;
		}
	`}
`

export interface IBoxInlineProps extends DOMAttributes<HTMLDivElement>, IBoxInlineStyledProps {
	className?: string;
	style?: CSSProperties;
	children: React.ReactNode;
}

export const BoxInline = ({ className, children, ...rest }: IBoxInlineProps) => {
	return (
		<BoxInlineStyled className={className} {...rest}>
			{children}
		</BoxInlineStyled>
	);
}