exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".web-static-platform-view-dialogs-dialog-confirm-dialog-confirm__dialogWidth__3hhdD {\n  width: 400px; }\n\n.web-static-platform-view-dialogs-dialog-confirm-dialog-confirm__bodyText__EvYtH {\n  line-height: 1.42857em; }\n\n.web-static-platform-view-dialogs-dialog-confirm-dialog-confirm__customFooter__htPOu {\n  display: flex;\n  justify-content: space-between; }\n\n.web-static-platform-view-dialogs-dialog-confirm-dialog-confirm__textAlignCenter__2VzAM {\n  text-align: center !important; }\n", "", {"version":3,"sources":["/home/ubuntu/projects/idemevents-web/web/static/platform/view/dialogs/dialog-confirm/dialog-confirm.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE;;AAEjB;EACE,uBAAuB,EAAE;;AAE3B;EACE,cAAc;EACd,+BAA+B,EAAE;;AAEnC;EACE,8BAA8B,EAAE","file":"dialog-confirm.scss","sourcesContent":[".dialogWidth {\n  width: 400px; }\n\n.bodyText {\n  line-height: 1.42857em; }\n\n.customFooter {\n  display: flex;\n  justify-content: space-between; }\n\n.textAlignCenter {\n  text-align: center !important; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"dialogWidth": "web-static-platform-view-dialogs-dialog-confirm-dialog-confirm__dialogWidth__3hhdD",
	"bodyText": "web-static-platform-view-dialogs-dialog-confirm-dialog-confirm__bodyText__EvYtH",
	"customFooter": "web-static-platform-view-dialogs-dialog-confirm-dialog-confirm__customFooter__htPOu",
	"textAlignCenter": "web-static-platform-view-dialogs-dialog-confirm-dialog-confirm__textAlignCenter__2VzAM"
};