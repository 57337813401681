import React, { CSSProperties } from 'react';
import styleConst from 'styles/style-const.scss';
import styled, { css } from 'styled-components';
import { themeColor } from 'styles/theme';

export type TLabelColor = keyof (typeof LABEL_COLOR);

export const LABEL_COLOR = {
	BLACK: styleConst.colorBlack,
	WHITE: styleConst.colorWhite,
	BLUE: styleConst.chartColor1,
	GREEN: styleConst.colorGreen,
	ORANGE: styleConst.chartColor3,
	MANGO: styleConst.colorMango,
	RED: styleConst.colorTomato,
	GRAY: styleConst.colorGray,
	DARK_GRAY: styleConst.colorDarkGray,
	GRAY2: styleConst.colorIdemGray2,
	BODY_BLACK: styleConst.colorBodyBlack,
	BROWN_GRAY: styleConst.colorBrownGray,

	IDEM_BLUE: styleConst.colorIdemBlue,
	IDEM_GREEN: styleConst.colorIdemGreen,
	IDEM_RED: styleConst.colorIdemRed,
	IDEM_ORANGE: styleConst.colorIdemOrange,
	IDEM_PURPLE: styleConst.colorIdemPurple,

	IDEM_DARK_BLUE: styleConst.colorIdemDarkBlue,
	IDEM_LIGHT_BLUE: styleConst.colorIdemLightBlue,
	IDEM_LIGHT_MANGO: styleConst.colorIdemLightMango,
	IDEM_LIGHT_GREEN: styleConst.colorIdemLightGreen,
	IDEM_LIGHT_ORANGE: styleConst.colorIdemLightOrange,
	IDEM_LIGHT_PURPLE: styleConst.colorIdemLightPurple,
	IDEM_LIGHT_RED: styleConst.colorIdemLightRed,

	IDEM_WEBSITE_BACKGROUND: styleConst.idemWebsiteBg,
	IDEM_WEBSITE_LINE: styleConst.idemWebsiteLine,

	G_DANGER: themeColor.brandRed,
	G_WARNING: themeColor.brandYellow,
	G_POSITIVE: themeColor.brandGreen,

	BRAND_ACCENT: themeColor.brandAccent,
	BRAND_ACCENT_HOVER: themeColor.brandAccentHover,
	BRAND_ACCENT_DISABLED: themeColor.brandAccentDisabled,

	BRAND_BLACK: themeColor.brandBlack,
	BRAND_BLACK_HOVER: themeColor.brandBlackHover,
	BRAND_BLACK_LIGHT: themeColor.brandBlackLight,
	BRAND_BLACK_MEDIUM: themeColor.brandBlackMedium,
	BRAND_BLACK_DISABLED: themeColor.brandBlackDisabled,

	BRAND_BLUE: themeColor.brandBlue,
	BRAND_BLUE_DISABLED: themeColor.brandBlueDisabled,

	BRAND_GREEN: themeColor.brandGreen,
	BRAND_GREEN_DISABLED: themeColor.brandGreenDisabled,

	BRAND_RED: themeColor.brandRed,
	BRAND_RED_DISABLED: themeColor.brandRedDisabled,

	BRAND_YELLOW: themeColor.brandYellow,
	BRAND_YELLOW_DISABLED: themeColor.brandYellowDisabled,

	PMS_REALIZED: '#6DB3D1',
	PMS_CUSTOM: '#CD97CE',

	TEXT_HYPERLINK: themeColor.textHyperlink
}

const LabelOutlineStyled = styled.span<{ fullWidth: boolean; small: boolean, bold: boolean }>`
	display: inline-block;
	border-radius: 20px;
	text-align: center;

	border: 1px solid;

	font-size: 14px;
	padding: 3px 5px;
	font-weight: 600;

	${({ small }) => small && css`
		font-size: 10px;
		padding: 0 15px;
		line-height: 20px;
		height: 20px;
		padding: 0 15px;
	`}

	${({ fullWidth }) => fullWidth && css`width: 100%`}

	${({ bold }) => bold && css`font-weight: bold`}
`

type TLabelOutlineProps = {
	text: string;
	colorCode?: TLabelColor;
	fullWidth?: boolean;
	style?: CSSProperties;
	small?: boolean;
	bold?: boolean;
}

export const LabelOutline = ({ text, colorCode = 'BRAND_GREEN', fullWidth = false, style = {}, small = false, bold = false }: TLabelOutlineProps) => {

	return (
		<LabelOutlineStyled
			fullWidth={fullWidth}
			small={small}
			bold={bold}
			style={{ color: getColor(colorCode), borderColor: LABEL_COLOR[colorCode], ...style }}
		>
			{text}
		</LabelOutlineStyled>
	);
}

const getColor = (colorCode: TLabelColor) => {
	if (['BRAND_YELLOW'].includes(colorCode)) return themeColor.fontBlack;

	if (['BRAND_BLACK_LIGHT'].includes(colorCode)) return themeColor.brandBlackMedium;

	if (['BRAND_BLUE_DISABLED'].includes(colorCode)) return themeColor.brandBlue;

	return LABEL_COLOR[colorCode];
}
