import React from 'react';
import cls from 'classnames';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { Container } from 'typedi';
import styles from './modal.scss';
import { DocumentService } from 'services/document.service';

interface IModalOptions {
	hideOverlay?: boolean;
	closeWhenClickOutside?: boolean;
	fullFrame?: boolean;
}

const defaultModalOptions = { hideOverlay: false, closeWhenClickOutside: true, fullFrame: false };

export class ModalModel<T = {}> {

	readonly id: number = Date.now();

	// Modal DOM ref
	dom: React.RefObject<HTMLDivElement> = React.createRef();

	@observable hideOverlay = false;
	@observable resolvedValue?: T;
	@observable closeWhenClickOutside: boolean = true;
	@observable fullFrame = false;

	@observable additionData: any;

	private documentService = Container.get(DocumentService);
	private callWhenDestroy: Array<() => any> = [];

	constructor(options: IModalOptions = defaultModalOptions) {
		const opts = {
			...defaultModalOptions,
			...options,
		};
		this.hideOverlay = opts.hideOverlay;
		this.closeWhenClickOutside = opts.closeWhenClickOutside;
		this.fullFrame = opts.fullFrame;

		const { unsubscribe } = this.documentService.onClickOutside(this.dom, () => {
			if (this.closeWhenClickOutside) {
				this.close();
			}
		});
		this.callWhenDestroy.push(unsubscribe);
	}

	@observable isClosed: boolean = false;
	@action close = (): void => {
		this.isClosed = true;
	}
	@action reset = (): void => {
		this.isClosed = false;
		this.callWhenDestroy.forEach(f => f());
	}

	@action setResolveValue = (value: T): this => {
		this.resolvedValue = value;
		return this;
	}

	@action setAdditionData = (value: any): void => {
		this.additionData = value;
	}

	@action setCloseWhenClickOutside = (value: boolean) => {
		this.closeWhenClickOutside = value;
	}
}

type TProps = {
	model: ModalModel;
	children: React.ReactNode;
	className?: string;
}

@observer
export class Modal extends React.Component<TProps> {
	render() {
		const { model } = this.props;
		if (model.isClosed) {
			return null;
		}

		const className = cls({
			[styles.modal]: true
		}, this.props.className);

		return (model.hideOverlay ?
			this.props.children
			:
			<div key="modal" className={className} id="modal">
				<div ref={model.dom} className={styles.content} style={model.fullFrame ? { paddingTop: '0', display: 'block', marginBottom: '0px' } : undefined}>
					{this.props.children}
				</div>
			</div>
		)
	}
}
