import React from 'react';
import { Container } from 'typedi';
import { Route, Switch } from 'react-router';
import { ERROR_CODES } from "components/common/general-error-boundary/general-error-boundary";
import { HistoryService } from 'services/history.service';

const historyService = Container.get(HistoryService);

export function SwitchSafe({ children, debugText }: { children: any, debugText: string }) {
    return (
        <Switch>
            {children}
            <Route component={() => <NotFound debugText={debugText} />} />
        </Switch>
    )
}

const NotFound = ({}: { debugText: string }) => {
    // console.log(debugText);
    throw new Error(ERROR_CODES.NOT_FOUND);
}

export function BasenameCheck() {
    const { basename } = historyService;
    const { pathname } = window.location;
    const hasBasename = pathname.startsWith(basename);

    return (hasBasename ? null : <NotFound debugText={`Error: pathname: [${pathname}] not start with basename: [${basename}]`} />)
}