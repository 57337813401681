export namespace ArrayUtil {
    export const compareTwoArray = (array1: Array<any>, array2: Array<any>) => {
        array1 = array1.sort();
        array2 = array2.sort();
        const eql = array1.length === array2.length && array1.every(function (value, index) { return value === array2[index] });
        return eql;
    }

    export const swapItems = (array: Array<any>, fromIndex: number, toIndex: number) => {
        const from = array[fromIndex];
        const to = array[toIndex];

        array.splice(fromIndex, 1, to);
        array.splice(toIndex, 1, from);
    }

    export const sortItems = (array: Array<any>, fromIndex: number, toIndex: number) => {
        const from = array[fromIndex];

        array.splice(fromIndex, 1);
        array.splice(toIndex, 0, from);
    }

    export const sortDescByPriorityKey = <T>(dataList: T[], priorityFunc: (item: T) => boolean, baseFunc: (item: T) => any): T[] => {
        const sortedDataList = dataList.sort((a, b) => {
            const priorityRate = priorityFunc(a) ? -1 : priorityFunc(b) ? 1 : 0;

            const baseA = baseFunc(a);
            const baseB = baseFunc(b);
            const baseRate = (baseA < baseB) ? -1 : (baseA > baseB) ? 1 : 0

            return priorityRate * 10 + baseRate;
        });

        return sortedDataList
    }

    export const flatDeep = (array: Array<any>, d = 1): Array<any> => {
        return d > 0 ? array.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
                     : array.slice();
     };
}
