import React from 'react';
import { History } from 'history';

import ErrorTemplate from '../error-template/error-template';
import { DocumentService } from 'services/document.service';
import Container from 'typedi';
import { HistoryService } from 'services/history.service';

export const enum ERROR_CODES {
    COMMON = '500',
    PERMISSION = '403',
    NOT_FOUND = '404'
}

type TProps = {
    history: History
}

const historyService = Container.get(HistoryService);
const documentService = Container.get(DocumentService);

class GeneralErrorBoundary extends React.Component<TProps> {

    state = {
        hasError: false,
        historyBack: false,
        code: ERROR_CODES.COMMON
    }

    componentDidCatch(error: any) {

        let code = ERROR_CODES.COMMON;

        if (error.message === ERROR_CODES.PERMISSION) {
            code = ERROR_CODES.PERMISSION;
        } else if (error.message === ERROR_CODES.NOT_FOUND) {
            code = ERROR_CODES.NOT_FOUND;
        }
        this.setState({ hasError: true, code });
    }

    componentDidUpdate(): void {
        if (this.state.historyBack) {
            setTimeout(() => documentService.redirect(documentService.getCurrentPath()), 100);
        }
    }

    render() {
        if (this.state.hasError) {
            const { code } = this.state;

            switch(code) {
                case ERROR_CODES.PERMISSION:
                    return (
                        <ErrorTemplate
                            code={code}
                            message="Sorry, you are not authorized to perform this action"
                            onGoBack={this.goBack}
                        />
                    );
                case ERROR_CODES.COMMON:
                case ERROR_CODES.NOT_FOUND:
                default:
                    const displayHomeButton = code === ERROR_CODES.NOT_FOUND;
                    return (
                        <ErrorTemplate
                            code={code}
                            message="We’re sorry, looks like something broke"
                            subMessages={
                                <React.Fragment>
                                    <div>We’ve logged the problem and will</div>
                                    <div>address it immediately.</div>
                                </React.Fragment>
                            }
                            onGoToHomePage={displayHomeButton ? this.goToHomePage : undefined}
                            onGoBack={this.goBack}
                            
                        />
                    )
            }
        }
        return this.props.children;
    }

    goBack = () => {
        this.props.history.goBack();
        this.setState({ historyBack: true, hasError: false });
    }

    goToHomePage = () => {
        historyService.navigate('/');
        this.setState({ hasError: false });
    }
}

export default GeneralErrorBoundary;
