import React from 'react';
import { ButtonIcon } from 'ui/button/button-icon/button-icon';
import { ModalModel } from 'components/modal/modal';

import styles from './dialog-notification.scss';
import { SVGIcon } from 'assets/svg';

type TProps = {
	onClose?: () => void;
	body: React.ReactNode;
	modalModel: ModalModel;
}

export function DialogNotification(props: TProps): React.ReactElement<TProps> {
	setTimeout(() => { props.modalModel.close() }, 3500);

	return (
		<div className={styles.notification}>
			<div className={styles.body}>{props.body}</div>
			<ButtonIcon
				noBackground
				icon={<SVGIcon.Cross />}
				className={styles.btnClose}
				onClick={props.modalModel.close}
			/>
		</div>
	)
}
