import * as Sentry from '@sentry/react';
import { Container, Service } from 'typedi';
import queryString from 'query-string';
import { computed, IObservableArray, observable, action } from 'mobx';

import { IUserJSON, User, PERMISSION, USER_ROLE, IHotelsJSON } from 'models/user.model';
import { ModalService } from './modal.service';
import { HotelService } from './hotel.service';
import { ContextService } from './context.service';
import { PREFIX, APIService, ICommonResponse, RES_STATUS } from './api.service';
import { DocumentService } from './document.service';
import { HistoryService } from './history.service';
import { GeneralService } from './general.service2';
import { DateTimeService } from './date-time.service';
import { IntercomService } from './intercom.service';
import { Hotel } from 'models/hotel.model';
import { IPaginationTableQuery } from './table.service';
import { AwsConnectService } from './aws-connect.service';

const contextService = Container.get(ContextService);
const dateTimeService = Container.get(DateTimeService);
const intercomService = Container.get(IntercomService);
const awsConnectService = Container.get(AwsConnectService);

interface ICurrentUserJSON {
	code: string,
	data: IUserJSON,
}

interface ICurrentUserHotelsJSON {
	code: string,
	data: IHotelsJSON,
}

interface ICurrentHotelUsersJSON {
	code: string,
	data: {
		results: IUserJSON[],
	}
}

interface IHotelUsersJSON {
	code: string,
	data: IUserJSON[]
}

export interface IAddUserPostJSON {
	first_name: string,
	last_name: string,
	role: USER_ROLE,
	email: string,
}

interface IAddUserResponse extends ICommonResponse {
	data?: IUserJSON,
}

interface IDeleteUserResponse extends ICommonResponse {
	data: {
		id: string,
		deleted_at: string,
	}
}

interface ITransferBlockResponse extends ICommonResponse {
	data: {
		block_count: number,
	}
}

export interface ITransferBlockJSON {
	from_id: string,
	to_id: string,
	[key: string]: string
}

interface IErrorJSON {
	field: string,
	message: string,
}

interface IChangeProfilePwdResponse {
	status: string,
	errors?: IErrorJSON[],
	message: string,
}

interface IGetMyHotelsQuery extends IPaginationTableQuery {
	search?: string;
}

@Service()
export class UserService {
	@observable currentUser: User | null = null;
	@observable UACUserlist: IObservableArray<User> = observable.array();

	private modalService = Container.get(ModalService);
	private hotelService = Container.get(HotelService);
	private apiService = Container.get(APIService);
	private documentService = Container.get(DocumentService);
	private historyService = Container.get(HistoryService);

	@computed get prefixPath(): string {
		return `${PREFIX.HOTELS}${this.hotelService.hotelId}/`;
	}

	async currentUserFlow(): Promise<void> {

		const hotelIdFromURL = this.documentService.getHotelIdFromURL();

		let userMeRes: Response, hotel: Hotel | null, generalRes: boolean,
			userJson: ICurrentUserJSON, user: User | undefined;

		const apis: any[] = [
			this.apiService.get(PREFIX.USERS_ME)
		]

		hotelIdFromURL && apis.push(
			this.hotelService.getHotelById(hotelIdFromURL),
			GeneralService.setTemplateKeys(hotelIdFromURL)
		);

		[userMeRes, hotel, generalRes] = (await Promise.allSettled(apis)).map((result: any) => result.value)

		userJson = await userMeRes.json()

		if (!(hotel && generalRes)) {

			const hotelId = userJson?.data?.hotel_id || 'current';

			const apis: any[] = [
				GeneralService.setTemplateKeys(contextService.isHotel ? hotelId : undefined)
			]

			contextService.isHotel && apis.push(this.hotelService.getHotelById(hotelId));
			[generalRes, hotel = null] = (await Promise.allSettled(apis)).map((result: any) => result.value)
		}

		if (hotel) {
			this.hotelService.setCurrentHotel(hotel);
		}

		if (userJson?.data?.id) {
			user = this.setUserAndHotels(userJson)
			await this.loadIntercomAndNavigateToMain(userMeRes, user)
		}

		// load AWS Chat widget
		awsConnectService.loadChatWidgetScript()
	};

	setUserAndHotels(userJson: ICurrentUserJSON): User {
		const user = new User(userJson?.data?.id).fromJSON(userJson.data);
		this.setCurrentUser(user);
		return user;
	}

	async loadIntercomAndNavigateToMain(userMeRes: Response | undefined, user: User) {
		const path = this.documentService.getCurrentPath();
		this.documentService.setCSRFToken(userMeRes?.headers.get('X-Idem-CSRF-Token') || '');

		await intercomService.loadIntercom(user);

		if (path === '/login') {
			this.historyService.navigate('/');
		}
	}

	async currentHotelUsersFlow() {
		const res = await this.apiService.get(`${this.prefixPath}users`);
		return this.apiService.parseResponseData(res, (json: ICurrentHotelUsersJSON) => this.replaceUserList(json.data.results), undefined);
	};

	async getMyHotels(query: IGetMyHotelsQuery = { limit: 20, page: 1 }): Promise<IHotelsJSON | undefined> {
		const q = queryString.stringify(query);
		const res = await this.apiService.get(`${PREFIX.USERS_ME}/hotels?${q}`);
		return this.apiService.parseResponseData(res, (json: ICurrentUserHotelsJSON) => json.data, undefined)
	}

	async getHotelUsers(): Promise<User[]> {
		const res = await this.apiService.get(`${this.prefixPath}account-managers`);

		const handler = (json: IHotelUsersJSON): User[] => {
			if (json.code === 'OK') {
				return json.data.map(u => {
					return new User(u.id).fromJSON(u);
				})
			}
			return [];
		}

		return this.apiService.parseResponseData(res, handler, []);
	}

	async addUser(data: IAddUserPostJSON) {
		const res = await this.apiService.post(`${this.prefixPath}users`, JSON.stringify(data));
		let message = '';

		try {
			const result: IAddUserResponse = await res.json();
			if (result.code === 'OK') {
				if (result.data) {
					const newUser = new User(result.data.id).fromJSON(result.data);
					action(() => {
						this.UACUserlist.push(newUser);
					})();

					message = 'New User Added';
				}

			} else {
				message = result.message;
			}
		} catch (e) {
			message = e.message;
			Sentry.captureException(e);
		}

		this.modalService.showNotification(message);
	};

	async deleteUser(user: User): Promise<any> {
		const res = await this.apiService.delete(`${this.prefixPath}users/${user.id}`);
		let message = '';

		try {
			const result: IDeleteUserResponse = await res.json();
			if (result.code === 'OK') {
				user.setDeletedAt(dateTimeService.buildDate(result.data.deleted_at))
			}
			message = result.message;
		} catch (e) {
			message = e.message;
			Sentry.captureException(e);
		}

		this.modalService.showNotification(message);
	};

	async restoreUser(user: User): Promise<any> {
		const res = await this.apiService.put(`${this.prefixPath}users/${user.id}/restore`);
		let message = '';

		try {
			const result: ICommonResponse = await res.json();
			if (result.code === 'OK') {
				user.setDeletedAt();
			}
			message = result.message;
		} catch (e) {
			message = e.message;
			Sentry.captureException(e);
		}

		this.modalService.showNotification(message);
	};

	async reinviteUser(user: User): Promise<any> {
		const res = await this.apiService.put(`${this.prefixPath}users/${user.id}/resend-invite`);
		let message = '';

		try {
			const result: ICommonResponse = await res.json();
			message = result.message;
		} catch (e) {
			message = e.message;
			Sentry.captureException(e);
		}

		this.modalService.showNotification(message);
	};

	async transferBlock(data: ITransferBlockJSON): Promise<boolean> {
		const req = await this.apiService.post(`${this.prefixPath}blocks/transfer`, JSON.stringify(data));
		let isDone = false;
		let msg = '';

		try {
			const res: ITransferBlockResponse = await req.json();
			if (res.code === 'OK') {
				const fromUSer = this.UACUserlist.find(u => u.id === data.from_id);
				const toUser = this.UACUserlist.find(u => u.id === data.to_id);

				if (fromUSer) {
					fromUSer.setBlockCount(0);
				}
				if (toUser) {
					toUser.setBlockCount(toUser.block_count + res.data.block_count);
				}

				msg = res.message;
				isDone = true;
			} else {
				msg = res.errors ? res.errors[0].message : res.message;
			}
		} catch (e) {
			msg = 'Fail, something went wrong!';
			Sentry.captureException(e);
		}

		this.modalService.showNotification(msg);
		return isDone;
	};

	async changeRole(userId: string, role: string): Promise<boolean> {
		const res = await this.apiService.put(`${this.prefixPath}users/${userId}/change_role`, JSON.stringify({ role }));
		return this.apiService.handleErrorBoolean(res);
	};

	getUserById(id: string): User | undefined {
		return this.UACUserlist.find(u => u.id === id);
	}

	@computed get currentUserRole(): USER_ROLE | null {
		return this.currentUser ? this.currentUser.role : null;
	}

	@computed
	get currentUserPermissions(): PERMISSION[] {
		return this.currentUser ? this.currentUser.permissions : [];
	}

	@computed get currentUserId(): string {
		return this.currentUser ? this.currentUser.id : '';
	}

	@computed
	get isAdminOrSuperUser(): boolean {
		if (!this.currentUser) {
			return false;
		}

		const { role } = this.currentUser;
		return role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_USER;
	}

	get isSuperUser(): boolean {
		if (!this.currentUser) {
			return false;
		}

		const { role } = this.currentUser;
		return role === USER_ROLE.SUPER_USER;
	}

	@action.bound
	private setCurrentUser(user: User): void {
		this.currentUser = user;
	}

	@action.bound
	private replaceUserList(list: IUserJSON[]): void {
		this.UACUserlist.replace(list.map(u => {
			return new User(u.id).fromJSON(u);
		}));
	}

	saveCurrentHotelProfile = async (data: any): Promise<boolean> => {
		const res = await this.apiService.put(PREFIX.USERS_ME, JSON.stringify(data));

		return this.apiService.handleErrorBoolean(res);
	}

	changeCurrentHotelProfilePwd = async (data: any): Promise<string> => {
		const res = await this.apiService.put(`${PREFIX.USERS_ME}/change-password`, JSON.stringify(data));

		const handler = (json: IChangeProfilePwdResponse): string => {
			if (json.status === RES_STATUS.SUCCESS) {
				return '';
			}

			return json.errors ? json.errors[0].message : json.message;
		}

		return this.apiService.parseResponseData(res, handler, 'Cannot change password at this time. Please try again!');
	}
}
