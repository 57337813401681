import { find } from 'lodash';
import queryString from 'query-string';
import Container from 'typedi';

import { clearWorthlessField } from 'utils/object-handler';
import { APIService, PREFIX } from './api.service';
import { DocumentService } from './document.service';
import { ContextService } from './context.service';
import { SessionStorageService } from './session-storage.service';
import { ModalService } from './modal.service';
import { ContentType } from './intercom.service'

const apiService = Container.get(APIService);
const modalService = Container.get(ModalService);
const contextService = Container.get(ContextService);
const documentService = Container.get(DocumentService);
const storageService = Container.get(SessionStorageService);

export namespace GeneralService {

	export const setTemplateKeys = async (hotelId: string = ''): Promise<boolean> => {
		const q = { hotel_id: hotelId };
		clearWorthlessField(q);
		const qString = queryString.stringify(q);
		const res = await apiService.get(`${PREFIX.GENERAL}?${qString}`);
		const json: any = await res.json();
		const data = json.data;

		if (data) {
			const setVar = documentService.setGlobalVariable;
			if (data.stripe !== undefined) {
				setVar('STRIPE_PUBLISH_KEY', data.stripe.publish_key);
				contextService.isGuest && storageService.set('stripePublishKey', data.stripe.publish_key);

				setVar('STRIPE_CLIENT_SECRET', data.stripe.client_secret);
				contextService.isGuest && storageService.set('stripeClientSecret', data.stripe.client_secret);
			}
			if (data.google !== undefined) setVar('GOOGLE_MAPS_JAVASCRIPT_API_URL', data.google.maps_api_url);
			if (data.currency_support !== undefined) setVar('CURRENCY_SUPPORT', data.currency_support);

			if (data.hotel !== undefined) {
				setVar('DEFAULT_UPSELL', data.hotel.default_upsell);
				setVar('DEFAULT_TAX_SETUP', data.hotel.default_tax_setup);
				setVar('DEFAULT_PROMO_SETUP', data.hotel.default_promo_setup);
				setVar('BASE_RATE_ENABLED', data.hotel.default_base_rate_enabled);
				setVar('GUEST_EDIT_ENABLED', data.hotel.default_guest_edit_enabled);
				setVar('GUEST_EDIT_DAYS_BEFORE', data.hotel.default_guest_edit_days_before);
				setVar('DIRECT_PAYMENT_ENABLED', data.hotel.default_payment_enabled);

				if (data.hotel.payment_portal) {
					setVar('PP_SETTINGS', data.hotel.payment_portal || null);
				}

				setVar('VOUCHER_SETTINGS', data.hotel.voucher || null);
				setVar('TAX_IDS', data.hotel.tax_ids || []);
			}

			if (data.links !== undefined) {
				setVar('$$STATIC_PATH', data.links.static_path);
			}

			if (data.emails !== undefined) {
				setVar('CONTACT_EMAIL', data.emails.default_contact_email);
			}

			if (data.apaleo) {
				setVar('APALEO_CLIENT_ID', data.apaleo.client_id);
				setVar('APALEO_SCOPES', data.apaleo.scopes);
			}

			if (data.merchantwarrior) {
				storageService.set('mwApiKey', data.merchantwarrior.api_key);
				storageService.set('mwClientId', data.merchantwarrior.client_id);
				storageService.set('mwEndPoint', data.merchantwarrior.endpoint);
				storageService.set('mwSecureEndpoint', data.merchantwarrior.secure_endpoint);
				setVar('ACCEPTED_CARDS', (data.merchantwarrior.accepted_cards || []).map((item: string) => item.toLowerCase()));
			}

			if (data.ip_info && Object.keys(data.ip_info).length) {
				storageService.set('userLocationInfo', data.ip_info);
			}

			if (data.intercom) {
				setVar('INTERCOM_APP_ID', data.intercom.app_id);
				setVar('INTERCOM_USER_ID', data.intercom.user_id || '');
				setVar('INTERCOM_USER_HASH', data.intercom.user_hash);
			}

			if (data.last_contents) {
				const getContentValue = (type: ContentType): string | null => find(data.last_contents, (content => content.type === type))?.id || null
				setVar('INTERCOM_CUSTOM_ATTRS', {
					hotel_id: hotelId,
					block_id: getContentValue('RoomBlock'),
					reservation_id: getContentValue('Reservation'),
					payment_id: getContentValue('TransactionGroup'),
					promo_code_id: getContentValue('PromoCode'),
					pkgs_management_id: getContentValue('Package'),
					voucher_order_id: getContentValue('Order'),
					accommodation_id: getContentValue('Accommodation'),
					booking_policy_id: getContentValue('BookingPolicy'),
					rate_code_id: getContentValue('HotelRatePlan'),
					venue_id: getContentValue('Venue'),
					outlet_id: getContentValue('Vendor')
				})
			}

			return true;
		}

		return false;
	};

	export const getUrlTargetType = async (domain: string): Promise<string> => {
		const res = await apiService.get(`${PREFIX.URL_TARGET_TYPE}?url=${domain}`);

		return apiService.parseResponseData(res, json => json.data.type, '', { ignoreFailureMsg: true });
	}

	export const exportFile = async (url: string): Promise<{ blob: Blob; fileName: string } | null> => {
		const res = await apiService.get(url);
		let msg = '';
		let returnedData = null;

		try {
			if (res.status === 200) {
				const blob = await res.blob();
				const fileName = res.headers.get('x-suggested-filename') || '';
				returnedData = { blob, fileName };
			} else {
				const data = await res.json();
				msg = data.message || 'Fail, something went wrong!';
			}
		} catch (e) {
			// console.log(e);
			msg = 'Fail, something went wrong!';
		}

		msg && modalService.showNotification(msg);
		return returnedData;
	}
}