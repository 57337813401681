import React, { ElementType } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ICustomRouteProps } from './route';
import Container from 'typedi';

import { GaService } from 'services/ga.service';

const gaService = Container.get(GaService);

type TRouteWithSubRoutesProps = ICustomRouteProps & {
    [key: string]: any;
}

export function RouteWithSubRoutes({ component, path, routes, redirectFunc, location, ...rest }: TRouteWithSubRoutesProps) {
    if (!!redirectFunc) {
        return (
            <Route
                path={path}
                render={(props) => <Redirect to={redirectFunc(props)} />}
                {...rest}
            />
        )
    }

    // TODO: it call twice
    // if (location) {
    //    console.log('location.pathname', location.pathname);
    // }

    if (location) {
        gaService.trackPageView();
    }

    const Comp: ElementType = component!;

    return (
        <Route
            path={path}
            render={(props) =>
                <Comp
                    {...props}
                    routes={routes}
                    location={location}
                    {...rest}
                />
            }
            {...rest}
        />
    );
}
