import { LOADING_TEXT } from "constants/input"
import { observable, action } from "mobx"
import { Service } from "typedi"

@Service()
export class LoadingService {
    loadingText: string = ''
    @observable isLoading: boolean = false
    @action setLoading(loading: boolean, text?: string) {
        this.loadingText = text || LOADING_TEXT
        this.isLoading = loading
    }
}