import React from 'react';
import cls from 'classnames';
import styles from './dialog.scss';

type TProps = {
	children?: React.ReactNode,
	className?: string,
}

export function Dialog(props: TProps): React.ReactElement<TProps> {
	const className = cls({
		[styles.dialog]: true,
	}, props.className);
	return (
		<div className={className}>
			{props.children}
		</div>
	)
}

export function DialogHeader(props: TProps): React.ReactElement<TProps> {
	const className = cls({
		[styles.header]: true,
	}, props.className);
	return (
		<header className={className}>
			{props.children}
		</header>
	)
}

export function DialogBody(props: TProps): React.ReactElement<TProps> {
	const className = cls({
		[styles.body]: true,
	}, props.className);
	return (
		<section className={className}>
			{props.children}
		</section>
	)
}

export function DialogFooter(props: TProps): React.ReactElement<TProps> {
	const className = cls({
		[styles.footer]: true,
	}, props.className);
	return (
		<footer className={className}>
			{props.children}
		</footer>
	)
}
