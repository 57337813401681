import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { themeColor } from 'styles/theme';

const TextLinkCss = css`
	text-decoration: underline;
    color: ${themeColor.textHyperlink};
    cursor: pointer;
`
const TextLinkA= styled.a`
    ${TextLinkCss}
`

const TextLinkLink = styled(Link)`
    ${TextLinkCss}
`

const TextLinkSpan = styled.span`
    ${TextLinkCss}
`

type TProps = {
    children: string;
    onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    to?: string;
    href?: string;
    target?: string;
    style?: CSSProperties;
    className?: string;
}

export const TextLink = ({ children, onClick, href, target, to, ...rest }: TProps) => {

    if (href) {
        return (
            <TextLinkA href={href} target={target} {...rest}>{children}</TextLinkA>
        );
    }

    if (to) {
        return (
            <TextLinkLink to={to} {...rest}>
                {children}
            </TextLinkLink>
        );
    }

    return (
        <TextLinkSpan
            onClick={(e) => onClick && onClick(e)}
            {...rest}
        >
            {children}
        </TextLinkSpan>
    )
}
