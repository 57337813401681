import React from 'react';
import ReactDOM from 'react-dom';
import { Container } from 'typedi';
import { Router } from 'react-router';
import ReactModal from 'react-modal';

// Global styles
import './app.scss';
import { HistoryService, TTargetType } from 'services/history.service';
import { ContextService } from 'services/context.service';
import GeneralErrorBoundary from 'components/common/general-error-boundary/general-error-boundary';
import { DocumentService } from 'services/document.service';
import { UserService } from 'services/user.service';
import { ScrollToTop } from 'ui/scroll-to-top/scroll-to-top';
import { SwitchSafe, BasenameCheck } from 'view/route/switch-safe';
import { buildDashboardRoutes, guestResRoutes, authRoutes, voucherRoutes, redemptionPortalRoutes } from 'view/route/route';
import { RouteWithSubRoutes } from 'view/route/route-with-sub-routes';
import { HotelService } from 'services/hotel.service';
import { GeneralService } from 'services/general.service2';
import { GaService } from 'services/ga.service';
import { Loading } from 'components/components/spinner/loading';
import { determineUserLanguage, initTranslator, TranslationService } from 'services/translation.service';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const gaService = Container.get(GaService);
const userService = Container.get(UserService);
const historyService = Container.get(HistoryService);
const contextService = Container.get(ContextService);
const documentService = Container.get(DocumentService);
const hotelService = Container.get(HotelService);

ReactModal.setAppElement('#app');

const App = () => {

	const { pathname, search } = window.location;

	if (contextService.isHotel) {
		const currentHotelId = userService.currentUser?.hotel_id ?? '';
		!currentHotelId && console.log('Can not get hotelId from hotel');

		(pathname === `${historyService.domainBasename}/`) && historyService.navigate(`${historyService.domainBasename}/hotels/${currentHotelId}`);
		(pathname === `${historyService.domainBasename}/pms/oauth-callback`) && historyService.navigate(`${historyService.domainBasename}/hotels/${currentHotelId}/pms/oauth-callback${search}`);
	}

	// historyService.setRouteType(contextService.isGuest, pathname);
	historyService.setBasename(contextService.isHotel ? hotelService.hotelId : null, pathname);

	return (
		<Router history={historyService.history}>
			<GeneralErrorBoundary history={historyService.history}>
				<ScrollToTop>
					<Loading />
					<BasenameCheck />
					<SwitchSafe debugText="app.tsx">
						{historyService.routeType === 'redemption' && <RouteWithSubRoutes {...redemptionPortalRoutes} />}
						{historyService.routeType === 'voucher' && <RouteWithSubRoutes {...voucherRoutes} />}
						{historyService.routeType === 'reservation' && <RouteWithSubRoutes {...guestResRoutes} />}
						{historyService.routeType === 'auth' && <RouteWithSubRoutes {...authRoutes} />}
						{historyService.routeType === 'dashboard' && <RouteWithSubRoutes {...buildDashboardRoutes(contextService.isHotel)} />}
					</SwitchSafe>
				</ScrollToTop>
			</GeneralErrorBoundary>
		</Router>
	)
};

const initApp = async (): Promise<void> => {
	// init Sentry
	Sentry.init({
		dsn: process.env.RAVEN_URL || '',
		integrations: [new BrowserTracing()],
		environment: process.env.RAVEN_ENV,
		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
	});

	documentService.setGlobalWebLinks();

	// load language file
	const lang = determineUserLanguage();
	(window as any)['determinedLanguage'] = lang
	Container.set(TranslationService, new TranslationService(await initTranslator(lang)))

	// check custom domain
	if (contextService.isGuest && !!documentService.getMetaContent('custom_domain')) {
		const type = await GeneralService.getUrlTargetType(location.hostname);

		if (type) {
			historyService.setTargetType(type as TTargetType);
			historyService.setRouteTypeByTargetType(type as TTargetType, location.pathname);
			handleLoadGoogleTag();
			return;
		}
	} else {
		historyService.setRouteType(contextService.isGuest, location.pathname);
		handleLoadGoogleTag();

		if (contextService.isGuest || historyService.routeType === 'auth') {
			return;
		}

		await userService.currentUserFlow();
	}
}

const handleLoadGoogleTag = () => {
	if (!historyService.isCustomGASite) {
		gaService.loadGoogleTag();
	}
}

export async function UI(): Promise<void> {
	await initApp();

	ReactDOM.render((
		<App />
	), document.getElementById('app'));
}
