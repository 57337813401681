import Container, { Service } from 'typedi';
import { createBrowserHistory, History, BrowserHistoryBuildOptions } from 'history';
import { action, observable } from 'mobx';
import { authRoutes } from 'view/route/route';
import { matchPath } from 'react-router';
import { DocumentService } from './document.service';
import { ContextService } from './context.service';

export type TTargetType = 'reservation' | 'voucher';
export type TRouteType = TTargetType | 'redemption' |'auth' | 'dashboard';

const contextService = Container.get(ContextService);
const documentService = Container.get(DocumentService);
const DOMAIN_WEBSITE_BASENAME = documentService.getMetaContent('website_basename');
const DOMAIN_HOTEL_BASENAME = documentService.getMetaContent('hotel_basename');
const DOMAIN_PLANNER_BASENAME = documentService.getMetaContent('planner_basename');

const RESERVATION_REGEX = new RegExp(`(${DOMAIN_WEBSITE_BASENAME})?/(r/)?[^/]*`);
const VOUCHER_REGEX = new RegExp(`(${DOMAIN_WEBSITE_BASENAME})?/v/[^/]*`);
const REDEMPTION_PORTAL_REGEX = new RegExp(`(${DOMAIN_WEBSITE_BASENAME})?/redemption(/)?[^/]*`);

@Service()
export class HistoryService {
	@observable isPrevented = false;
	@action setIsPrevented = (bol: boolean): void => {
		this.isPrevented = bol;
	}

	history: History = createBrowserHistory();
	setHistory = (options?: BrowserHistoryBuildOptions) => {
		this.history = createBrowserHistory(options)
	}

	navigate(path: string) {
		this.history.push(path);
	}

	replace(path: string) {
		this.history.replace(path);
	}

	routeType: TRouteType = 'dashboard';
	basename: string = '/';
	domainBasename = '';

	targetType?: TTargetType;

	setTargetType = (type: TTargetType) => {
		this.targetType = type;
	}

	setRouteType = (isGuestDomain: boolean, currentPath: string) => {
		let type: TRouteType = 'dashboard';
		this.domainBasename = contextService.isHotel ? DOMAIN_HOTEL_BASENAME : DOMAIN_PLANNER_BASENAME;

		if (isGuestDomain) {
			this.domainBasename = DOMAIN_WEBSITE_BASENAME;
			
			if (VOUCHER_REGEX.test(currentPath)) {
				type = 'voucher';
			} else if (REDEMPTION_PORTAL_REGEX.test(currentPath)) {
				type = 'redemption';
			} else {
				type = 'reservation';
			}
		} else {
			const subPath = currentPath.replace(this.domainBasename, '');
			
			if (authRoutes.routes!.some(r => !!matchPath(subPath, r))) {
				type = 'auth';
			}
		}

		this.routeType = type;
	}

	setRouteTypeByTargetType = (type: TTargetType, currentPath: string) => {
		if (type === 'voucher' && REDEMPTION_PORTAL_REGEX.test(currentPath)) {
			this.routeType = 'redemption';
		} else {
			this.routeType = type;
		}
	}

	setBasename = (hotelId: string | null, currentPath: string) => {
		let basename = `${this.domainBasename || '/'}`;

		if (this.routeType === 'reservation') {
			const execArray = RESERVATION_REGEX.exec(currentPath);
			if (execArray) {
				basename = execArray[0];  /* exp: /:basename/future-well */
			}
		} else if (this.routeType === 'voucher') {
			const execArray = VOUCHER_REGEX.exec(currentPath);
			if (execArray) {
				basename = execArray[0];  /* exp: /v/future-well */
			}
		} else if (this.routeType === 'redemption') {
			basename = this.targetType
						? '/redemption'
						: REDEMPTION_PORTAL_REGEX.exec(currentPath)![0]; /* exp: /redemption/future-well */
		} else if (this.routeType === 'dashboard' && !!hotelId) {
			basename = `${DOMAIN_HOTEL_BASENAME}/hotels/${hotelId}`;
		}

		this.basename = basename;
		this.history = createBrowserHistory({ basename });
	}

	get isCustomGASite(): boolean {
		return this.routeType === 'voucher' || this.routeType === 'redemption';
	}
}
